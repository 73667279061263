import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { Icon } from 'src/chrome'
import { useRef, useState } from 'src/hooks'

import ContactMenu from './lib/contact-menu'
import styles from './contactActionBar.module.scss'
import { ContactFragment, GroupFragment } from 'src/legacy_graphql'
import {
  Div,
  Flex,
  Icon as QDSIcon,
  Spacer,
  Text,
  Tooltip,
} from '@sendoutcards/quantum-design-ui'
import { Group } from 'src/contacts/contactTypes'
import { Button } from 'src/design_system/molecules/button/Button'
import HoverTooltip from 'src/chrome/HoverTooltip/HoverTooltip'
interface Props {
  title: string
  totalCount: number
  selectedCount: number
  isViewingGroups: boolean
  onToggleSelectAll: () => void
  onSendCard: () => void
  onViewGroups: () => void
  onStartCampaign: () => void
  onAddToGroup?: () => void
  onDelete: () => void
  onShare?: () => void
  onExport?: () => void
  onFindDup?: () => void
  onManageMembers?: (group: Group) => void
  onEditGroup?: (group: Group) => void
  handleEditContact?: (id?: string) => void
  handleHistoryClick?: (contact: ContactFragment) => void
  selectedGroups?: GroupFragment[]
  contact?: ContactFragment
}

const ContactActionBar: React.FC<Props> = props => {
  const {
    contact,
    handleEditContact,
    handleHistoryClick,
    onToggleSelectAll,
    onSendCard,
    onViewGroups,
    onStartCampaign,
    onAddToGroup,
    onDelete,
    onShare,
    onExport,
    onManageMembers,
    onEditGroup,
    selectedGroups = [],
    title,
    selectedCount,
    totalCount,
    isViewingGroups,
    onFindDup,
  } = props

  const anchorRef = useRef<HTMLButtonElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [hoveringOn, setHoveringOn] = useState('')

  const tooltipVariants = {
    hoverOn: {
      opacity: 1,
    },
    hoverOff: {
      opacity: 0,
    },
  }
  const groupOrContact = isViewingGroups ? 'group' : 'contact'
  const tooltipMessageForMany = `You must select one or more ${groupOrContact}s\n to make this button active.`

  const tooltipMessage = `You must only select one ${groupOrContact}\n to make this button active.`

  const shouldSelectOnlyOne = selectedCount === 0 || selectedCount > 1
  const shouldSelectOneOrMore = selectedCount === 0

  const openMenu = () => setIsMenuOpen(true)

  const closeMenu = () => setIsMenuOpen(false)

  return (
    <div className={styles.container}>
      {selectedCount >= 0 && (
        <>
          <div className={styles.actions}>
            <div className={styles.desktopActions}>
              <HoverTooltip
                title={tooltipMessage}
                shouldDisplayTooltip={!handleEditContact || !contact}
              >
                <Button
                  title={{
                    content: 'History',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="7px 25px"
                  isDisabled={!handleHistoryClick || !contact}
                  onClick={
                    contact && handleHistoryClick
                      ? () => handleHistoryClick(contact)
                      : () => {}
                  }
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                />
              </HoverTooltip>
              {!isViewingGroups && contact && (
                <HoverTooltip
                  title={tooltipMessage}
                  shouldDisplayTooltip={!handleEditContact || !contact}
                >
                  <Button
                    id={`edit_contact_btn`}
                    title={{
                      content: 'Edit',
                      fontColor: '#e841b3',
                      fontSize: '15px',
                    }}
                    padding="7px 25px"
                    isDisabled={!handleEditContact || !contact}
                    onClick={
                      handleEditContact && contact
                        ? () => handleEditContact(contact.id)
                        : () => {}
                    }
                    fill="#fff"
                    borderRadius="10px"
                    borderColor="#e841b3"
                    isOutlined={true}
                  >
                    <QDSIcon
                      name="variable"
                      size="xSmall"
                      primaryColor="#e841b3"
                    />
                  </Button>
                </HoverTooltip>
              )}
              {onAddToGroup && !shouldSelectOneOrMore && (
                <HoverTooltip
                  title={tooltipMessageForMany}
                  shouldDisplayTooltip={shouldSelectOneOrMore}
                >
                  <Button
                    id={'add_to_group_btn'}
                    title={{
                      content: 'Add to Group',
                      fontColor: '#e841b3',
                      fontSize: '15px',
                    }}
                    padding="5px 25px"
                    isDisabled={shouldSelectOneOrMore}
                    onClick={() => {
                      setHoveringOn('')
                      onAddToGroup()
                    }}
                    fill="#fff"
                    borderRadius="10px"
                    borderColor="#e841b3"
                    isOutlined={true}
                  >
                    <QDSIcon
                      name="multipleUsers3x"
                      size="small"
                      primaryColor="#e841b3"
                    />
                  </Button>
                </HoverTooltip>
              )}
              {isViewingGroups && !shouldSelectOnlyOne && (
                <HoverTooltip
                  title={tooltipMessage}
                  shouldDisplayTooltip={shouldSelectOnlyOne}
                >
                  <Button
                    id={'edit_group_btn'}
                    title={{
                      content: 'Edit',
                      fontColor: '#e841b3',
                      fontSize: '15px',
                    }}
                    padding="7px 25px"
                    isDisabled={shouldSelectOnlyOne}
                    onClick={() =>
                      onEditGroup?.({
                        id: selectedGroups[0].id,
                        name: selectedGroups[0].name,
                      })
                    }
                    fill="#fff"
                    borderRadius="10px"
                    borderColor="#e841b3"
                    isOutlined={true}
                  >
                    <QDSIcon
                      name="variable"
                      size="xSmall"
                      primaryColor="#e841b3"
                    />
                  </Button>
                </HoverTooltip>
              )}
              {isViewingGroups && !shouldSelectOnlyOne && (
                <HoverTooltip
                  title={tooltipMessage}
                  shouldDisplayTooltip={shouldSelectOnlyOne}
                >
                  <Button
                    id={'manage_members_btn'}
                    title={{
                      content: 'Manage Members',
                      fontColor: '#e841b3',
                      fontSize: '15px',
                    }}
                    padding="7px 25px"
                    isDisabled={shouldSelectOnlyOne}
                    onClick={() =>
                      onManageMembers?.({
                        id: selectedGroups[0].id,
                        name: selectedGroups[0].name,
                      })
                    }
                    fill="#fff"
                    borderRadius="10px"
                    borderColor="#e841b3"
                    isOutlined={true}
                  >
                    <QDSIcon
                      name="groups"
                      size="xSmall"
                      primaryColor="#e841b3"
                    />
                  </Button>
                </HoverTooltip>
              )}
              <HoverTooltip
                title={tooltipMessageForMany}
                shouldDisplayTooltip={shouldSelectOneOrMore}
              >
                <Button
                  id={'send_campaign_btn'}
                  title={{
                    content: 'Campaign',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="7px 25px"
                  isDisabled={shouldSelectOneOrMore}
                  onClick={() => onStartCampaign()}
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                >
                  <QDSIcon
                    name="catalog"
                    size="xSmall"
                    primaryColor="#e841b3"
                  />
                </Button>
              </HoverTooltip>
              <HoverTooltip
                title={tooltipMessageForMany}
                shouldDisplayTooltip={shouldSelectOneOrMore}
              >
                <Button
                  id={'send_card_btn'}
                  title={{
                    content: 'Send Card',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="4px 25px"
                  isDisabled={shouldSelectOneOrMore}
                  onClick={() => onSendCard()}
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                >
                  <Icon icon="SEND" size={14} color="#e841b3" />
                </Button>
              </HoverTooltip>
              <HoverTooltip
                title={tooltipMessageForMany}
                shouldDisplayTooltip={shouldSelectOneOrMore}
              >
                <Button
                  id={'delete_contact_btn'}
                  title={{
                    content: 'Delete',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="7px 25px"
                  isDisabled={shouldSelectOneOrMore}
                  onClick={() => onDelete()}
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                >
                  <QDSIcon name="delete" size="xSmall" primaryColor="#e841b3" />
                </Button>
              </HoverTooltip>
              <HoverTooltip
                title={tooltipMessageForMany}
                shouldDisplayTooltip={shouldSelectOneOrMore}
              >
                <Button
                  id={'share_contact_btn'}
                  title={{
                    content: 'Share',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="7px 25px"
                  isDisabled={shouldSelectOneOrMore}
                  onClick={onShare}
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                >
                  <QDSIcon name="redo" size="xSmall" primaryColor="#e841b3" />
                </Button>
              </HoverTooltip>
              <HoverTooltip
                title={tooltipMessageForMany}
                shouldDisplayTooltip={shouldSelectOneOrMore}
              >
                <Button
                  id={'export_csv_btn'}
                  title={{
                    content: 'export',
                    fontColor: '#e841b3',
                    fontSize: '15px',
                  }}
                  padding="4px 25px"
                  isDisabled={shouldSelectOneOrMore}
                  onClick={onExport}
                  fill="#fff"
                  borderRadius="10px"
                  borderColor="#e841b3"
                  isOutlined={true}
                >
                  <Icon icon="NEWFILE" size={14} color="#e841b3" />
                </Button>
              </HoverTooltip>
              {onFindDup && contact && (
                <HoverTooltip
                  title={tooltipMessage}
                  shouldDisplayTooltip={!contact}
                >
                  <Button
                    title={{
                      content: 'Find Duplicate Contact',
                      fontColor: '#e841b3',
                      fontSize: '15px',
                    }}
                    padding="8px 25px"
                    isDisabled={!contact}
                    onClick={onFindDup}
                    fill="#fff"
                    borderRadius="10px"
                    borderColor="#e841b3"
                    isOutlined={true}
                  />
                </HoverTooltip>
              )}
            </div>
            <Div
              className={styles.showSm}
              onHoverStart={() => {
                setHoveringOn('Actions')
              }}
              onHoverEnd={() => {
                setHoveringOn('')
              }}
              position="relative"
            >
              <IconButton
                id={'icon_menu'}
                aria-label="more"
                aria-controls="icon-menu"
                aria-haspopup="true"
                onClick={openMenu}
                ref={anchorRef}
              >
                <MoreVertIcon fontSize="large" />
              </IconButton>
              {hoveringOn === 'Actions' && (
                <Flex
                  variants={tooltipVariants}
                  animate={hoveringOn ? 'hoverOn' : 'hoverOff'}
                  initial={'hoverOff'}
                  transition={{ ease: 'easeIn', duration: 0.2 }}
                  top="55px"
                  left="-15%"
                  position="absolute"
                  zIndex={100}
                >
                  <Tooltip title="Actions" direction="top" />
                </Flex>
              )}
            </Div>
            <ContactMenu
              anchorEl={anchorRef.current ?? undefined}
              onClose={closeMenu}
              onToggleSelectAll={onToggleSelectAll}
              onSendCard={onSendCard}
              onViewGroups={onViewGroups}
              onStartCampaign={onStartCampaign}
              onAddToGroup={onAddToGroup}
              onDelete={onDelete}
              onShare={onShare}
              onExport={onExport}
              isEverySelected={selectedCount === totalCount}
              isAnySelected={selectedCount > 0}
              isOpen={isMenuOpen}
            />
          </div>
          <div className={styles.contextDetails}>
            <Text type="largeBody">{title}</Text>
            <Spacer space="x2" orientation="horizontal" />
            <Text type="body">{totalCount} total</Text>
            <Spacer space="x2" orientation="horizontal" />
            <Text type="body">{selectedCount} selected</Text>
          </div>
        </>
      )}
    </div>
  )
}

export default ContactActionBar
