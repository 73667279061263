import { queryKeyStore } from '../queryKeyStore'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useContext } from 'react'
import { useEffect, useSelector } from 'src/hooks'
import useUpdateCheckoutVouchers from 'src/hooks/useUpdateCheckoutVouchers'
import { CheckoutContext } from 'src/saleor/context/CheckoutContext'
import {
  base64URLEncode,
  generateCodeVerifier,
  getPersistedSaleorAuth,
  persistSaleorAccessToken,
  sha256,
} from 'src/saleor/utils/auth'
import {
  FontFragment,
  GetAlertsQueryVariables,
  GetCampaignOrdersQueryVariables,
  GetCampaignQueryVariables,
  GetCardQueryVariables,
  GetContactRequestByTokenQueryVariables,
  GetDraftedCardsQueryVariables,
  GetImagesQueryVariables,
  GetItemQueryVariables,
  GetItemsQueryVariables,
  GetLayoutQueryVariables,
  GetLayoutsQueryVariables,
  GetNotificationsQueryVariables,
  GetOrderQueryVariables,
  GetPaginatedRecipientsQueryVariables,
  GetPlanMoveQueryVariables,
  GetRemindersQueryVariables,
  GetSendableCardQueryVariables,
  GetSentCardsCountQueryVariables,
  GetSentCardsQueryVariables,
  GetSharedCampaignsQueryVariables,
  GetSharedContactsQueryVariables,
  GetSponsorQueryVariables,
  GetUsersQueryVariables,
  StickerFilters,
} from 'src/graphql/generated/graphql'
import * as graphql from 'src/graphql/generated/graphql'
import {
  AttachCustomerMutationVariables,
  CampaignStoreSubcategoryQueryVariables,
  CheckoutFragment,
  CheckoutQueryVariables,
  CompleteCheckoutMutationVariables,
  DeleteCheckoutLinesMutationVariables,
  InitializeTransactionMutationVariables,
  ProductVariantQueryVariables,
  ProductVariantsQueryVariables,
  SaleorOrderQueryVariables,
  UpdateCheckoutBillingAddressMutationVariables,
  UpdateCheckoutEmailMutationVariables,
  UpdateCheckoutLineQuantityMutationVariables,
} from 'src/saleor_graphql/generated/graphql'
import {
  addCheckoutLines,
  addCheckoutPromoCode,
  attachCustomerToCheckout,
  authorizeSaleorOIDC,
  completeCheckout,
  createCheckout,
  createCheckoutPayment,
  createTransaction,
  deleteCheckoutLines,
  getProductVariants,
  getSaleorAccessToken,
  getSaleorRefreshToken,
  removeCheckoutPromoCode,
  updateCheckoutBillingAddress,
  updateCheckoutEmail,
  updateCheckoutLineQuantity,
  updateSaleorCustomer,
} from './fetching'
import { connectionFactory } from 'src/react_query/utils/cursorPagination'
import { useOffsetLimitPagination } from 'src/hooks/useGetOffsetLimitPagination'
import { browserHistory } from 'src/redux/browserHistory'

export const useAccountQuery = ({
  enabled,
  suspense,
}: { enabled?: boolean; suspense?: boolean } = {}) => {
  const res = useQuery({
    ...queryKeyStore.account.detail,
    enabled,
    suspense,
  })

  return res
}

export const useAddPaymentSettingsDailyCap = ({
  enabled,
  suspense,
}: { enabled?: boolean; suspense?: boolean } = {}) => {
  const res = useQuery({
    ...queryKeyStore.daily_cap.add_payment_settings,
    enabled,
    suspense,
  })

  return res
}

export const useAffiliateCountries = () => {
  const res = useQuery(queryKeyStore.affiliate_countries.list)

  return {
    ...res,
    data: res.data?.affiliateCountries,
  }
}

export const useAlerts = (
  resultsPerPage: number = 10,
  variables: Omit<GetAlertsQueryVariables, 'offset' | 'limit'> = {},
) => {
  return useInfiniteQuery({
    queryKey: queryKeyStore.alerts.infinite({
      ...variables,
      offset: 0,
      limit: resultsPerPage,
    }).queryKey,
    queryFn: args => {
      const { pageParam = 0 } = args

      return queryKeyStore.alerts
        .infinite({ ...variables, offset: pageParam, limit: resultsPerPage })
        .queryFn(args)
    },
    getNextPageParam: (lastPage, allPages) => {
      const hasMore =
        lastPage.paginatedCampaignShares.hasMore ||
        lastPage.paginatedContactShares.hasMore ||
        lastPage.paginatedNotifications.hasMore ||
        lastPage.paginatedReminders.hasMore

      const totalCampaignSharesFetched = allPages.reduce(
        (prev, curr) => prev + curr.paginatedCampaignShares.results.length,
        0,
      )
      const totalContactSharesFetched = allPages.reduce(
        (prev, curr) => prev + curr.paginatedContactShares.results.length,
        0,
      )
      const totalNotificationsFetched = allPages.reduce(
        (prev, curr) => prev + curr.paginatedNotifications.results.length,
        0,
      )
      const totalRemindersFetched = allPages.reduce(
        (prev, curr) => prev + curr.paginatedReminders.results.length,
        0,
      )

      const maxFetched = Math.max(
        totalCampaignSharesFetched,
        totalNotificationsFetched,
        totalRemindersFetched,
        totalContactSharesFetched,
      )

      if (!hasMore) {
        return undefined
      }

      return maxFetched
    },
  })
}

export const useAvailableDailyHeartfelt = ({
  enabled,
  suspense,
}: { enabled?: boolean; suspense?: boolean } = {}) => {
  const res = useQuery({
    ...queryKeyStore.daily_cap.heartfelt,
    enabled,
    suspense,
  })

  return res
}

export const useAvailableUnlimitedDailyHeartfelt = ({
  enabled,
  suspense,
}: { enabled?: boolean; suspense?: boolean } = {}) => {
  const res = useQuery({
    ...queryKeyStore.daily_cap.unlimited_heartfelt,
    enabled,
    suspense,
  })

  return res
}

export const useByDesignLoginLink = () =>
  useQuery({
    ...queryKeyStore.bydesign_login_link.detail,
    staleTime: 60_000, // 1 minute
  })

export const useDetailedCountries = () => useQuery(queryKeyStore.countries.list)

export const usePlanMove = (planIdFrom: number, planIdTo: number) => {
  return useQuery(queryKeyStore.plan_move.detail({ planIdFrom, planIdTo }))
}

export const usePromptingsCoachDiscounts = () =>
  useQuery(queryKeyStore.promptings_coach_discounts.detail)

export const usePromptingsEventDates = () =>
  useQuery(queryKeyStore.marketing_content.promptings_event_dates)

export const useAccountCreatedContents = () => {
  return useQuery({
    ...queryKeyStore.marketing_content.account_created,
  })
}
export const useNotifications = (variables: GetNotificationsQueryVariables) => {
  return useQuery(queryKeyStore.notification.list(variables))
}

export const useNotificationCount = () =>
  useQuery(queryKeyStore.notification.count)

export const useReceivedPendingMembershipInvites = () => {
  return useQuery(queryKeyStore.received_pending_membership_invites.detail)
}

export const useReminders = (variables: GetRemindersQueryVariables) => {
  return useQuery(queryKeyStore.reminders.detail(variables))
}

export const useSharedCampaigns = (
  variables: GetSharedCampaignsQueryVariables,
) => {
  return useQuery(queryKeyStore.shared_campaigns.detail(variables))
}

export const useSharedContacts = (
  variables: GetSharedContactsQueryVariables,
) => {
  return useQuery(queryKeyStore.shared_contacts.detail(variables))
}

export const useContactRequests = () => {
  return useQuery(queryKeyStore.contact_requests.list)
}

export const useContactRequestByToken = (
  variables: GetContactRequestByTokenQueryVariables,
) => {
  return useQuery(queryKeyStore.contact_requests.by_token(variables))
}

export const useGetEarnedBadges = () => {
  return useQuery(queryKeyStore.coaching_badges.list({ hasEarned: true }))
}

export const useGetInProgressBadges = () => {
  const query = useQuery(
    queryKeyStore.coaching_badges.list({ hasEarned: false }),
  )
  return query
}

// This should not every pass empty string as we have this query marked as dependent with the enabled flag
// See https://tanstack.com/query/latest/docs/react/guides/dependent-queries
export const useGetCard = (
  variables: Partial<GetCardQueryVariables>,
  { suspense }: { suspense?: boolean },
) => {
  return useQuery({
    ...queryKeyStore.card.detail({ id: variables.id ?? '' }),
    enabled: !!variables.id,
    suspense,
  })
}

export const useGetSendableCard = (
  variables: Partial<GetSendableCardQueryVariables>,
  { suspense }: { suspense?: boolean },
) => {
  return useQuery({
    ...queryKeyStore.sendableCard.detail({ id: variables.id ?? '' }),
    enabled: !!variables.id,
    suspense,
  })
}

export const useGetItemCategories = ({ suspense }: { suspense?: boolean }) =>
  useQuery({ ...queryKeyStore.itemCategory.list, suspense })

export const useItem = (variables: GetItemQueryVariables) =>
  useQuery({ ...queryKeyStore.item.detail(variables), suspense: true }) // TODO - Change this to no longer suspend when we change Campaign View UI

export const useItems = (variables: GetItemsQueryVariables) =>
  useQuery({ ...queryKeyStore.item.list(variables) })

export const useLeaderBoards = () => useQuery(queryKeyStore.leader_board.list)

export const useCampaign = (variables: GetCampaignQueryVariables) =>
  useQuery({ ...queryKeyStore.campaign.detail(variables) })

export const usePaginatedSentCards = (
  variables: Omit<GetSentCardsQueryVariables, 'offset' | 'limit'> & {
    page: number
  },
  { enabled, suspense }: { enabled?: boolean; suspense?: boolean } = {},
) => {
  const { page, ...vars } = variables
  const resultsPerPage = 20
  const { getOffset, limit } = useOffsetLimitPagination(resultsPerPage)
  const offset = getOffset(page)
  return useQuery({
    ...queryKeyStore.sent_card.list_paginated({ offset, limit, ...vars }),
    enabled,
    suspense,
  })
}

export const useSentCardsCount = (
  variables: GetSentCardsCountQueryVariables,
  {
    enabled,
  }: {
    enabled?: boolean
  } = {},
) => useQuery({ ...queryKeyStore.sent_card.count(variables), enabled })

export const useDraftedCards = (variables: GetDraftedCardsQueryVariables) =>
  useQuery({
    ...queryKeyStore.drafted_card.list(variables),
  })

export const useCampaignOrders = (
  variables: Omit<GetCampaignOrdersQueryVariables, 'offset' | 'limit'>,
) => {
  const resultsPerPage = 3
  const { getNextPageParam, getOffset, limit } = useOffsetLimitPagination(
    resultsPerPage,
  )

  return useInfiniteQuery({
    queryKey: queryKeyStore.infinite_campaign_order.list({
      id: variables.id,
      offset: 0,
      limit,
    }).queryKey,
    queryFn: args => {
      const { pageParam = 1 } = args
      const offset = getOffset(pageParam)

      return queryKeyStore.infinite_campaign_order
        .list({
          id: variables.id,
          offset,
          limit,
        })
        .queryFn(args)
    },
    getNextPageParam,
  })
}

export const usePaginatedInvites = ({ page }: { page: number }) => {
  const resultsPerPage = 20
  const { getOffset, limit } = useOffsetLimitPagination(resultsPerPage)
  const offset = getOffset(page)
  return useQuery({
    ...queryKeyStore.paginated_invites.list({ offset, limit }),
  })
}

export const useInfiniteInvites = () => {
  const resultsPerPage = 20
  const { getNextPageParam, getOffset, limit } = useOffsetLimitPagination(
    resultsPerPage,
  )

  return useInfiniteQuery({
    queryKey: queryKeyStore.paginated_invites.infinite({
      offset: 0,
      limit,
    }).queryKey,
    queryFn: args => {
      const { pageParam = 1 } = args
      const offset = getOffset(pageParam)

      return queryKeyStore.paginated_invites
        .infinite({
          offset,
          limit,
        })
        .queryFn(args)
    },
    getNextPageParam,
  })
}

// TODO we should refactor this to fetch single pieces/pages of marketing content instead of everything at once
export const useMarketingContent = () => {
  return useQuery({
    ...queryKeyStore.marketing_content.list,
  })
}

export const useLeaderBoardMarketingContent = () => {
  return useQuery({
    ...queryKeyStore.marketing_content.leader_board,
  })
}

export const useMarketingBanners = () => {
  return useQuery(queryKeyStore.marketing_banner.list)
}

export const useFlags = ({ suspense }: { suspense?: boolean }) => {
  const query = useQuery({
    ...queryKeyStore.flags.list,
    suspense,
  })
  return {
    ...query,
    data: query.data?.flags,
  }
}

export const useProductionInfo = (
  variables: graphql.GetProductionInfoQueryVariables,
  { enabled, suspense }: { enabled?: boolean; suspense?: boolean } = {},
) => {
  const query = useQuery({
    ...queryKeyStore.production_info.detail(variables),
    enabled,
    suspense,
  })

  return {
    ...query,
    data: query.data?.productionInfo,
  }
}

export const usePaginatedRecipients = (
  variables: Omit<GetPaginatedRecipientsQueryVariables, 'offset' | 'limit'> & {
    page: number
  },
  { enabled, suspense }: { enabled?: boolean; suspense?: boolean } = {},
) => {
  const { page, ...vars } = variables
  const resultsPerPage = 20
  const { getOffset, limit } = useOffsetLimitPagination(resultsPerPage)
  const offset = getOffset(page)
  const query = useQuery({
    ...queryKeyStore.recipients.list({ offset, limit, ...vars }),
    enabled,
    suspense,
  })

  return {
    ...query,
    data: query.data?.paginatedRecipients,
  }
}

export const useLayouts = (
  variables: GetLayoutsQueryVariables,
  isEnabled: boolean = true,
) => {
  const query = useQuery({
    ...queryKeyStore.layout.list(variables),
    enabled: !!isEnabled,
  })
  return {
    ...query,
    data: query.data?.layouts,
  }
}

export const useLayout = (variables?: GetLayoutQueryVariables) => {
  const query = useQuery({
    ...queryKeyStore.layout.detail(variables ?? { id: 'DNE' }), // This should technically never happen with the enabled flag below
    enabled: !!variables?.id,
  })
  return {
    ...query,
    data: query.data?.layout,
  }
}

export const useImages = (
  variables: GetImagesQueryVariables,
  isEnabled: boolean = true,
) => {
  const query = useQuery({
    ...queryKeyStore.images.list(variables),
    enabled: isEnabled,
  })

  return {
    ...query,
    data: query.data?.images,
  }
}

export const useCreditsBalance = (isEnabled: boolean = true) => {
  const query = useQuery({
    ...queryKeyStore.credits_balance.detail,
    enabled: isEnabled,
  })

  return {
    ...query,
    data: query.data?.account.credits,
  }
}

export const useOrder = (variables: GetOrderQueryVariables) => {
  const query = useQuery({ ...queryKeyStore.order.detail(variables) })

  return {
    ...query,
    data: query.data?.order,
  }
}

export const useFonts = () => {
  const query = useQuery({ ...queryKeyStore.font.list })
  const signatures: FontFragment[] = []
  const fonts: FontFragment[] = []

  const allFonts = query.data?.fonts

  if (allFonts) {
    for (const font of allFonts) {
      if (font.isSig) {
        // tslint:disable-next-line:no-array-mutation
        signatures.push(font)
      } else {
        // tslint:disable-next-line:no-array-mutation
        fonts?.push(font)
      }
    }
  }

  return {
    ...query,
    data: {
      all: allFonts,
      signatures,
      fonts,
    },
  }
}

export const useGetPlanMove = (
  variables: GetPlanMoveQueryVariables,
  shouldNotTrigger?: boolean,
) => {
  const planMoveDetail = queryKeyStore.plan_move.detail(variables)
  return useQuery({
    ...planMoveDetail,
    enabled: !!!shouldNotTrigger,
  })
}

export const usePlans = ({
  suspense,
}: {
  suspense?: boolean
} = {}) => useQuery({ ...queryKeyStore.plan.list, suspense })

/**
 * Performs OIDC authentication, first it gets the authenticatioon code from /soc_oauth2/authorize_frontend (instead of the usual flow of directing the user to /authenticate).
 * Once the authentication code is present it passes it to the /token endpoint to retrieve the access_token that can then be used on all Saleor
 * requests. The access token is persisted in local storage, if the token is found the user won't be reauthenticated it will just be returned from LS.
 * If the token is close to expiring (1 hour), a refresh token will be used to obtain a new acccess_token.
 *
 * @returns access token details (type SaleorAuthentication)
 */
export const useSaleorAuth = () => {
  const redirectUri = window.location.href.split('#')[0]

  const account = useSelector(state => state.user.account)

  const persistedSaleorCustomer = getPersistedSaleorAuth()
  const ONE_HOUR = 3_600_000
  const isTokenExpiring =
    persistedSaleorCustomer &&
    Date.now() + ONE_HOUR >= persistedSaleorCustomer.expires_at

  const authorizationCodeGrantMutation = useMutation({
    mutationFn: async () => {
      const codeVerifier = generateCodeVerifier()
      const codeChallenge = base64URLEncode(await sha256(codeVerifier))

      const code = await authorizeSaleorOIDC({
        codeChallenge,
        redirectUri,
      })
      const data = await getSaleorAccessToken(code, codeVerifier, redirectUri)
      if (!data || !data.success) {
        throw new Error('Error getting access token...')
      }
      return data
    },
    retry: 3,
    onSuccess: data => {
      persistSaleorAccessToken(data)
    },
    onError: error => {
      console.error('Error authorizing OIDC:', error)
    },
  })

  const refreshTokenMutation = useMutation({
    mutationFn: async ({ refreshToken }: { refreshToken: string }) => {
      const data = await getSaleorRefreshToken(refreshToken)
      if (!data || !data.success) {
        throw new Error('Error refreshing access token...')
      }
      return data
    },
    retry: 3,
    onSuccess: data => {
      persistSaleorAccessToken(data)
    },
    onError: error => {
      // If token refresh failed, just try to get a new token
      console.error('Error refreshing token:', error)
      authorizationCodeGrantMutation.mutate()
    },
  })

  useEffect(() => {
    if (account?.email && account?.username) {
      if (persistedSaleorCustomer) {
        if (isTokenExpiring) {
          refreshTokenMutation.mutate({
            refreshToken: persistedSaleorCustomer.refresh_token,
          })
        }
      } else {
        authorizationCodeGrantMutation.mutate()
      }
    }
    // We only want to re-run this effect when either:
    // 1. The user changes
    // 2. The token will expire soon
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account?.email,
    account?.username,
    persistedSaleorCustomer,
    isTokenExpiring,
  ])

  return getPersistedSaleorAuth()
}

export const useCheckout = (variables?: Omit<CheckoutQueryVariables, 'id'>) => {
  const { state } = useContext(CheckoutContext)

  const id = state.checkout ?? ''
  return useQuery({
    ...queryKeyStore.checkout.detail({ ...variables, id }),
    enabled: !!id,
  })
}

export const useCoachingDashboardMarketingContent = () => {
  return useQuery({
    ...queryKeyStore.marketing_content.coaching_dashboard,
  })
}

export const useCreditCardManagerMarketingContent = ({
  enabled,
  suspense,
}: { enabled?: boolean; suspense?: boolean } = {}) => {
  return useQuery({
    ...queryKeyStore.marketing_content.credit_card_manager,
    enabled,
    suspense,
  })
}

export const useCreateCheckout = () => {
  const queryClient = useQueryClient()
  const { updateCheckoutVouchers } = useUpdateCheckoutVouchers()

  return useMutation({
    mutationFn: createCheckout,
    onSettled: data => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error('There was a problem creating the checkout')
      }
      if (data?.checkout) {
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set('checkout', data.checkout.id)
        searchParams.delete('checkout')
        searchParams.append('checkout', data.checkout.id)
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`

        browserHistory.push(newUrl)

        // Window popstate event doesn't trigger when history state changes so we dispatch a custom event
        // See Checkout context for this event listener
        window.dispatchEvent(new Event('update_checkout'))

        // Manually write to the cache with the mutation response
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
        updateCheckoutVouchers(data.checkout)
      }
    },
  })
}

export const useUpdateCheckoutBillingAddress = () => {
  const queryClient = useQueryClient()
  const { updateCheckoutVouchers } = useUpdateCheckoutVouchers()
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: (
      variables: Omit<UpdateCheckoutBillingAddressMutationVariables, 'id'>,
    ) => updateCheckoutBillingAddress({ ...variables, id }),
    onSettled: data => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error('There was a problem updating the checkout')
      }
      if (data?.checkout) {
        // Manually write to the cache with the mutation response
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
        updateCheckoutVouchers(data.checkout)
      }
    },
  })
}

export const useAddCheckoutLines = () => {
  const queryClient = useQueryClient()
  const { updateCheckoutVouchers } = useUpdateCheckoutVouchers()

  return useMutation({
    mutationFn: addCheckoutLines,
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error('There was a problem adding the line to the checkout')
      }
      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
        updateCheckoutVouchers(data.checkout)
      }
    },
  })
}

export const useAddCheckoutPromoCode = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addCheckoutPromoCode,
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error('There was a problem adding the promo code to the checkout')
      }
      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
      }
    },
  })
}

export const useRemoveCheckoutPromoCode = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: removeCheckoutPromoCode,
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error(
          'There was a problem removing the promo code from the checkout',
        )
      }
      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
      }
    },
  })
}

export const useSaleorCustomer = () => {
  return useQuery({
    ...queryKeyStore.saleor_customer.detail,
  })
}

export const useUpdateSaleorCustomer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateSaleorCustomer,
    onSettled: data => {
      if (data?.errors && data.errors.length) {
        // TODO - How do we want to handle errors?
        throw Error('There was a problem creating the checkout')
      }
      if (data?.user) {
        queryClient.setQueryData(
          queryKeyStore.saleor_customer.detail.queryKey,
          data.user,
        )
      }
    },
  })
}

export const useDeleteCheckoutLines = () => {
  const queryClient = useQueryClient()
  const { state } = useContext(CheckoutContext)
  const { updateCheckoutVouchers } = useUpdateCheckoutVouchers()
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: (variables: Omit<DeleteCheckoutLinesMutationVariables, 'id'>) =>
      deleteCheckoutLines({ ...variables, id }),
    onMutate: async variables => {
      const queryKey = queryKeyStore.checkout.detail({
        id,
      }).queryKey
      await queryClient.cancelQueries({ queryKey })
      const previousCheckout = queryClient.getQueryData<CheckoutFragment>(
        queryKey,
      )

      if (previousCheckout) {
        queryClient.setQueryData<CheckoutFragment>(queryKey, old => {
          const deleteLines =
            typeof variables.linesIds === 'string'
              ? [variables.linesIds]
              : variables.linesIds

          if (old) {
            return {
              ...old,
              lines: old.lines.filter(
                line => !deleteLines.some(id => id === line.id),
              ),
            }
          }
          return undefined
        })
      }

      return { previousCheckout }
    },
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length > 0) {
        throw Error('Could not delete checkout line')
      }

      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id }).queryKey,
          data.checkout,
        )
        updateCheckoutVouchers(data.checkout)
      }
    },
  })
}

export const useProductVariant = (
  variables: ProductVariantQueryVariables,
  isEnabled?: boolean,
) => {
  return useQuery({
    ...queryKeyStore.saleor_product_variant.detail({
      ...variables,
      channel: 'default-channel',
    }),
    enabled: isEnabled,
  })
}

export const useProductVariants = (
  variables: ProductVariantsQueryVariables,
  isEnabled?: boolean,
) => {
  return useInfiniteQuery({
    queryFn: ({ pageParam }) =>
      getProductVariants({
        first: variables.first,
        after: pageParam,
        ids: variables.ids,
        filter: variables.filter,
      }),
    queryKey: queryKeyStore.saleor_infinite_product_variants.list(variables)
      .queryKey,

    getNextPageParam: lastPage => {
      if (lastPage?.pageInfo.hasNextPage) {
        return lastPage.pageInfo.endCursor
      }
      return undefined
    },
    enabled: isEnabled,
  })
}

export const useInitializeTransaction = () => {
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: (
      variables: Omit<
        InitializeTransactionMutationVariables,
        'paymentGateway' | 'id'
      > & { stripeCustomerId: string; stripeSourceId?: string },
    ) => {
      const { stripeCustomerId, stripeSourceId, ...rawVariables } = variables

      return createTransaction({
        ...rawVariables,
        id: id ?? '',
        paymentGateway: {
          id: 'app.saleor.stripe',
          data: {
            customer: stripeCustomerId,
            payment_method: stripeSourceId,
          },
        }, // We only have 1 payment gateway so this is hard coded for now instead of fetching uneccessarily
      })
    },
  })
}

export const useCheckoutPayment = () => {
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: () =>
      createCheckoutPayment({
        id,
        input: { gateway: 'saleor.payments.stripe' },
      }),
  })
}

export const useCompleteCheckout = () => {
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''
  const account = useSelector(state => state.user.account)

  return useMutation({
    mutationFn: (variables: Omit<CompleteCheckoutMutationVariables, 'id'>) =>
      completeCheckout({
        ...variables,
        id,
        paymentData: JSON.stringify({
          payment_method_id: account?.stripeSource?.id,
          off_session: false,
        }),
      }),
    retry: 3,
    retryDelay: attempt => attempt * 1000, // Retry creating the order 3 times (1000ms delay between)
  })
}

export const useSaleorOrder = (variables: SaleorOrderQueryVariables) => {
  return useQuery(queryKeyStore.saleor_order.detail(variables))
}

export const useSponsor = (
  variables: GetSponsorQueryVariables,
  isEnabled: boolean = true,
) => {
  return useQuery({
    ...queryKeyStore.sponsor.detail(variables),
    enabled: isEnabled,
  })
}

export const useUpdateCheckoutEmail = () => {
  const queryClient = useQueryClient()
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: (variables: Omit<UpdateCheckoutEmailMutationVariables, 'id'>) =>
      updateCheckoutEmail({ ...variables, id }),
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length > 0) {
        throw Error('Could not update checkout email')
      }

      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id }).queryKey,
          data.checkout,
        )
      }
    },
  })
}

export const useCustomerAttach = () => {
  const queryClient = useQueryClient()
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''

  return useMutation({
    mutationFn: (
      variables: Omit<AttachCustomerMutationVariables, 'id'> & {
        token?: string
      },
    ) => attachCustomerToCheckout({ ...variables, id }, variables.token),
    onSettled: (data, _err) => {
      if (data?.errors && data.errors.length > 0) {
        throw Error('Could not attach customer to order')
      }

      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id }).queryKey,
          data.checkout,
        )
      }
    },
  })
}

export const useCampaignStoreSubcategories = (paginationOpts: {
  resultsPerPage: number
}) => {
  return useInfiniteQuery({
    keepPreviousData: true,
    queryKey: queryKeyStore.infinite_campaign_store_subcategories.list({
      subcategoriesFirst: paginationOpts.resultsPerPage,
    }).queryKey,
    queryFn: args => {
      const pageParam = {
        direction: args.pageParam?.direction ?? 'forward',
        cursor: args.pageParam?.cursor,
      }
      const connection = connectionFactory(
        pageParam.direction as 'forward' | 'backward',
        paginationOpts.resultsPerPage,
        pageParam.cursor,
      )
      return queryKeyStore.infinite_campaign_store_subcategories
        .list({
          subcategoriesLast: connection.last,
          subcategoriesBefore: connection.before,
          subcategoriesAfter: connection.after,
          subcategoriesFirst: connection.first ?? paginationOpts.resultsPerPage,
        })
        .queryFn(args)
    },
    getNextPageParam: (
      lastPage,
    ): { cursor: string; direction: 'forward' | 'backward' } | undefined => {
      if (lastPage?.pageInfo.hasNextPage && lastPage.pageInfo.endCursor) {
        return { cursor: lastPage.pageInfo.endCursor, direction: 'forward' }
      }

      return undefined
    },
    getPreviousPageParam: (
      nextPage,
    ): { cursor: string; direction: 'forward' | 'backward' } | undefined => {
      if (nextPage?.pageInfo.hasNextPage && nextPage.pageInfo.startCursor) {
        return { cursor: nextPage.pageInfo.startCursor, direction: 'backward' }
      }
      return undefined
    },
  })
}

export const useCampaignStoreSubcategoryProducts = (
  variables: CampaignStoreSubcategoryQueryVariables,
  isEnabled?: boolean,
  resultsPer: number = 10,
) => {
  // const queryClient = useQueryClient()
  return useInfiniteQuery({
    enabled: !!isEnabled,
    queryKey: queryKeyStore.infinite_campaign_store_subcategory_products.list({
      productsFirst: resultsPer ?? variables.productsFirst,
      ...variables,
    }).queryKey,
    queryFn: args => {
      const pageParam = {
        direction: args.pageParam?.direction ?? 'forward',
        cursor:
          args.pageParam?.cursor ??
          (variables.productsAfter || variables.productsBefore),
      }
      const connection = connectionFactory(
        pageParam.direction as 'forward' | 'backward',
        resultsPer,
        pageParam.cursor,
      )
      return queryKeyStore.infinite_campaign_store_subcategory_products
        .list({
          id: variables.id,
          productsBefore: connection.before,
          productsAfter: connection.after,
          productsFirst: connection.first ?? resultsPer,
          productsLast: connection.last,
        })
        .queryFn(args)
    },
    getNextPageParam: (
      lastPage,
    ): { cursor: string; direction: 'forward' | 'backward' } | undefined => {
      if (lastPage?.pageInfo.hasNextPage && lastPage.pageInfo.endCursor) {
        return { cursor: lastPage.pageInfo.endCursor, direction: 'forward' }
      }

      return undefined
    },
    getPreviousPageParam: (
      nextPage,
    ): { cursor: string; direction: 'forward' | 'backward' } | undefined => {
      if (nextPage?.pageInfo.hasNextPage && nextPage.pageInfo.startCursor) {
        return { cursor: nextPage.pageInfo.startCursor, direction: 'backward' }
      }
      return undefined
    },
  })
}

export const useUpdateCheckoutLineQuantity = () => {
  const queryClient = useQueryClient()
  const { updateCheckoutVouchers } = useUpdateCheckoutVouchers()
  const { state } = useContext(CheckoutContext)
  const id = state.checkout ?? ''
  const queryKey = queryKeyStore.checkout.detail({
    id,
  }).queryKey
  const mutation = useMutation({
    mutationFn: async (
      variables: Omit<UpdateCheckoutLineQuantityMutationVariables, 'id'>,
    ) => {
      return updateCheckoutLineQuantity({ ...variables, id })
    },
    onMutate: async variables => {
      await queryClient.cancelQueries({ queryKey })
      const previousCheckout = queryClient.getQueryData<CheckoutFragment>(
        queryKey,
      )

      if (previousCheckout) {
        queryClient.setQueryData<CheckoutFragment>(queryKey, old => {
          const lines = Array.isArray(variables.lines)
            ? variables.lines
            : [variables.lines]

          if (old) {
            return {
              ...old,
              lines: old.lines.map(line => {
                const match = lines.find(l => l.lineId === line.id)
                if (match) {
                  return {
                    ...line,
                    quantity: match.quantity ?? line.quantity,
                  }
                }
                return line
              }),
            }
          }
          return undefined
        })
      }

      return { previousCheckout }
    },

    onSettled: async (data, error, _variables, context) => {
      const errorRes = data?.errors
      const queryKey = queryKeyStore.lines.list({
        checkoutId: id,
      }).queryKey

      if ((errorRes && errorRes.length > 0) || error) {
        queryClient.setQueryData(queryKey, context?.previousCheckout)
      }

      if (data?.checkout) {
        queryClient.setQueryData(
          queryKeyStore.checkout.detail({ id: data.checkout.id }).queryKey,
          data.checkout,
        )
        updateCheckoutVouchers(data.checkout)
      }
    },
  })
  return mutation
}

export const useUserCardTokens = () => {
  const res = useQuery({
    ...queryKeyStore.user_card_tokens.detail,
  })

  return res
}

export const useStickers = (filters?: StickerFilters) => {
  const { getNextPageParam, getOffset, limit } = useOffsetLimitPagination(3)

  return useInfiniteQuery({
    queryKey: queryKeyStore.infinite_stickers.list({
      offset: 0,
      limit: 1,
      filters,
    }).queryKey,
    queryFn: args => {
      const { pageParam = 1 } = args
      const offset = getOffset(pageParam)

      return queryKeyStore.infinite_stickers
        .list({
          offset,
          limit,
          filters,
        })
        .queryFn(args)
    },
    getNextPageParam: (lastPage, allPages) =>
      getNextPageParam(
        { ...lastPage, count: lastPage.totalCount ?? 0 },
        allPages.map(page => ({ ...page, count: page.totalCount ?? 0 })),
      ),
  })
}

export const useUsers = (
  variables: GetUsersQueryVariables,
  isEnabled?: boolean,
) => useQuery({ ...queryKeyStore.user.list(variables), enabled: isEnabled })
