import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import DateDropdown from '../DateDropdown/DateDropdown'
// @src imports
import {
  CreateContactInput,
  CreateSubContactInput,
  GroupFragment,
  Maybe,
  RelatedContactFragment,
} from 'src/legacy_graphql'
import { Icon, Tooltip } from 'src/chrome'
import {
  useEffect,
  useEffectOnChange,
  useMemo,
  useMutations,
  useSelector,
  useState,
} from 'src/hooks'

import styles from './addressForm.module.scss'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  Button,
  Div,
  Input,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import ContactForm from '../ContactForm/ContactForm'
import ContactDetails from '../ContactDetails/ContactDetails'
import uuid from 'src/utils/uuid'
import { GroupAddCreateModal } from '..'
import { Group } from 'src/contacts/contactTypes'
import useCountryAndRegions from 'src/hooks/useCountryAndRegions'

export type FormContact = {
  id?: string
  firstName: string | null
  lastName: string | null
  address1: string | null
  address2: string | null
  company: string | null
  city: string | null
  homePhone?: string | null
  email?: string | null
  state: string | null
  country: string | null
  postalCode: string | null
  birthday?: OptionalBirthdayAnniversaryInput
  anniversary?: OptionalBirthdayAnniversaryInput
  spouse?: string
  spouseContact?: CreateSubContactInput | null
  children?: Array<CreateSubContactInput>
}

type OptionalBirthdayAnniversaryInput = {
  day: number
  month: number
  year?: number | null
} | null

const isEmptyDate = (date: OptionalBirthdayAnniversaryInput) =>
  date && !date.month && !date.day && !date.year

const isValidDate = (
  date: OptionalBirthdayAnniversaryInput,
): date is OptionalBirthdayAnniversaryInput =>
  Boolean(
    (date && date.month && date.day && date.year) ||
      (date && date.month && date.day),
  )

const getValidDate = (date: OptionalBirthdayAnniversaryInput) =>
  isValidDate(date) ? date : undefined

export const NO_STATE_SELECTION = 'State'

interface Props {
  shouldShowDates: boolean | undefined
  style?: React.CSSProperties
  contact?: FormContact
  isSaving?: boolean
  shouldHideActions?: boolean
  checkboxText?: string
  secondaryCheckboxText?: string
  isSecondaryCheckboxChecked?: boolean
  confirmButtonText?: string
  disableCountryReason?: string
  errorMessages?: Maybe<string>[]
  showAddSpouse?: boolean
  showAddChildren?: boolean
  showAddGroup?: boolean
  groups?: GroupFragment[]
  showPhoneNumber?: boolean
  onSubmit?: (
    contact: CreateContactInput,
    isCheckboxChecked: boolean,
    isSecondaryCheckboxChecked: boolean,
    accountPhoneNumber?: string,
  ) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCountryChange?: (country: string) => void
  onStateChange?: (state: string) => void
  toggleAddressBlinker?: (toggle: boolean) => void
  close?: () => void
  isTitleVisible: boolean
  alignAction?: 'flex-start' | 'center' | 'flex-end'
}

const AddressForm: React.FC<Props> = props => {
  const {
    contact,
    style,
    shouldShowDates,
    isTitleVisible,
    onCountryChange,
    onStateChange,
    disableCountryReason,
    isSaving,
    shouldHideActions,
    checkboxText,
    secondaryCheckboxText,
    confirmButtonText,
    errorMessages,
    onSubmit,
    onChange,
    toggleAddressBlinker,
    close,
    showAddSpouse,
    showAddChildren,
    showAddGroup,
    groups,
    showPhoneNumber = false,
    alignAction = 'center',
  } = props
  const contactTemplate: RelatedContactFragment = {
    id: '',
    firstName: '',
    company: '',
    birthday: null,
    lastName: contact?.lastName || '',
    address1: contact?.address1 || '',
    address2: contact?.address2 || '',
    city: contact?.city || '',
    state: contact?.state || '',
    postalCode: contact?.postalCode || '',
    country: contact?.country || '',
    anniversary: null,
    isPrimary: false,
    homePhone: null,
    cellPhone: null,
    workPhone: null,
    faxNumber: null,
    website: '',
    emailAddress: null,
  }
  const [newContactId] = useState(uuid())
  const [selectedGroup, setSelectedGroup] = useState('Select a group')
  const [firstName, setFirstName] = useState(contact?.firstName ?? '')
  const [lastName, setLastName] = useState(contact?.lastName ?? '')
  const [address1, setAddress1] = useState(contact?.address1 ?? '')
  const [address2, setAddress2] = useState(contact?.address2 ?? '')
  const [company, setCompany] = useState(contact?.company ?? '')
  const [city, setCity] = useState(contact?.city ?? '')
  const [state, setState] = useState(contact?.state ?? NO_STATE_SELECTION)
  const [country, setCountry] = useState(contact?.country ?? 'United States')
  const [postalCode, setPostalCode] = useState(contact?.postalCode ?? '')
  const [spouse, setSpouse] = useState<RelatedContactFragment>(contactTemplate)
  const [children, setChildren] = useState<Array<RelatedContactFragment>>([])
  const [editChildIndex, setEditChildIndex] = useState(-1)
  const [showSpouseForm, setShowSpouseForm] = useState(false)
  const [showChildForm, setShowChildForm] = useState(false)
  const [showAddGroupModal, setShowAddGroupModal] = useState(false)
  const [birthday, setBirthday] = useState<OptionalBirthdayAnniversaryInput>(
    contact?.birthday?.day && contact?.birthday?.month
      ? {
          day: contact.birthday.day,
          month: contact.birthday.month,
          year: contact?.birthday?.year ?? null,
        }
      : null,
  )
  const [anniversary, setAnniversary] = useState<
    OptionalBirthdayAnniversaryInput
  >(
    contact?.anniversary?.day && contact?.anniversary?.month
      ? {
          day: contact.anniversary.day,
          month: contact.anniversary.month,
          year: contact?.anniversary?.year ?? null,
        }
      : null,
  )

  const {
    countries,
    regions,
    isRegionsUnavailable,
    generatedStateOptions,
    generatedCountryOptions,
  } = useCountryAndRegions(country)

  const mutations = useMutations()

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  const [isSecondaryCheckboxChecked, setIsSecondaryCheckboxChecked] = useState(
    props.isSecondaryCheckboxChecked ?? false,
  )
  const isInvalidRegion = !isRegionsUnavailable && !regions?.includes(state)
  const isBirthdayInvalid = !isEmptyDate(birthday) && !isValidDate(birthday)
  const isAnniversaryInvalid =
    !isEmptyDate(anniversary) && !isValidDate(anniversary)

  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const isMobile = useSelector(state => state.window.isMobile)

  const isPhoneInvalid = phoneNumber.search(/[^0-9 ()+-]+/) !== -1

  const isFormNotValid = useMemo(
    () =>
      (state === NO_STATE_SELECTION && !isRegionsUnavailable) ||
      !firstName ||
      !lastName ||
      !address1 ||
      !city ||
      !postalCode ||
      isPhoneInvalid,
    [
      isRegionsUnavailable,
      firstName,
      lastName,
      address1,
      city,
      state,
      postalCode,
      isPhoneInvalid,
    ],
  )

  const updateState = (newState: string) => {
    if (onStateChange) {
      onStateChange(newState)
    } else {
      setState(newState)
    }
  }

  const handleSubmit = () => {
    if (isFormNotValid) return
    const newContact: CreateContactInput = Object.assign(
      {},
      {
        id: contact?.id ? contact.id : newContactId,
        firstName,
        lastName,
        address1,
        address2,
        companyName: company,
        city,
        state: isRegionsUnavailable ? '' : state,
        country,
        postalCode,
        birthday: shouldShowDates ? getValidDate(birthday) : undefined,
        anniversary: shouldShowDates ? getValidDate(anniversary) : undefined,
        children,
      },
      selectedGroup !== 'none' ? { groupName: selectedGroup } : {},
      spouse.id ? { spouseContact: spouse } : {},
    )
    onSubmit?.(
      newContact,
      isCheckboxChecked,
      isSecondaryCheckboxChecked,
      phoneNumber !== '' ? phoneNumber : undefined,
    )
    toggleAddressBlinker?.(false)
  }

  const handleChange = (e: { target: { value: string; name: string } }) => {
    if (onChange) {
      onChange(e as React.ChangeEvent<HTMLInputElement>)
    }
    return true
  }

  useEffectOnChange(() => {
    if (isRegionsUnavailable) {
      updateState(' ')
    }
  }, [country])

  useEffect(() => {
    if (!contact) return

    setFirstName(contact.firstName ?? '')
    setLastName(contact.lastName ?? '')
    setAddress1(contact.address1 ?? '')
    setAddress2(contact.address2 ?? '')
    setCompany(contact.company ?? '')
    setCity(contact.city ?? '')
    setState(contact.state ?? '')
    setCountry(contact.country ?? '')
    setPostalCode(contact.postalCode ?? '')
    setBirthday(contact.birthday ?? null)
    setAnniversary(contact.anniversary ?? null)
  }, [contact])

  const resetSpouse = () => {
    setSpouse(contactTemplate)
  }

  const onFinishSpouse = (addedSpouse: RelatedContactFragment) => {
    setSpouse({ ...addedSpouse, id: uuid() })
    setShowSpouseForm(false)
  }

  const onFinishChild = (addedChild: RelatedContactFragment) => {
    const addedChildWithId = { ...addedChild, id: uuid() }
    setChildren(prevChildren => {
      if (editChildIndex >= 0) {
        const newChildrenArr = Object.assign([], prevChildren, {
          [editChildIndex]: addedChildWithId,
        })
        return newChildrenArr
      }
      return [...prevChildren, addedChildWithId]
    })
    setShowChildForm(false)
    setEditChildIndex(-1)
  }

  const createGroup = async (group: Omit<Group, 'id'>) => {
    await mutations.createGroup({
      group: {
        name: group.name,
        description: group.description,
      },
    })
    setShowAddGroupModal(false)
  }

  const deleteChild = (childIndex: number) => {
    setChildren(oldChildren =>
      oldChildren.filter((oldChild, oldChildInd) => oldChildInd !== childIndex),
    )
  }

  const prefillData = (contactTemp: RelatedContactFragment) => {
    return {
      ...contactTemp,
      lastName,
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
    }
  }

  return (
    <Div
      className={`${styles.addressForm} ${contact ? styles.editContact : ''}`}
      style={style}
    >
      <div className={styles.formContainer}>
        {isTitleVisible && (
          <div className={`${styles.flex} ${styles.drawerHeader}`}>
            <Icon icon={'ADDUSER'} />
            <Spacer space="x_5" orientation="horizontal" />
            <Text type="body">
              {contact ? 'Edit address' : 'Add a new address'}
            </Text>
          </div>
        )}
        <Div
          justifyContent="space-between"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          outset={{ vertical: 'x1_5' }}
          width="100%"
        >
          <Div
            outset={isMobile ? { vertical: 'x1_5' } : undefined}
            width={isMobile ? '100%' : '49%'}
          >
            <Input
              id={'first_name_field'}
              onChange={e =>
                handleChange({
                  target: { value: e, name: 'firstName' },
                }) && setFirstName(e)
              }
              value={firstName}
              placeholder={'First Name'}
              type="text"
              message={{
                type: 'danger',
                content: firstName ? '' : 'this field is required',
              }}
              isFullWidth={true}
            />
          </Div>
          <Div width={isMobile ? '100%' : '49%'}>
            <Input
              id={'last_name_field'}
              onChange={e => {
                handleChange({ target: { value: e, name: 'lastName' } }) &&
                  setLastName(e)
              }}
              value={lastName}
              name="lastName"
              message={{
                type: 'danger',
                content: lastName ? '' : 'this field is required',
              }}
              placeholder={'Last Name'}
              type="text"
              isFullWidth={true}
            />
          </Div>
        </Div>
        <Div>
          <Input
            id={'address1_field'}
            onChange={e =>
              handleChange({ target: { value: e, name: 'address1' } }) &&
              setAddress1(e)
            }
            value={address1}
            message={{
              type: 'danger',
              content: address1 ? '' : 'this field is required',
            }}
            placeholder={'Address 1'}
            isFullWidth={true}
            type="text"
          />
        </Div>
        <Div outset={{ vertical: 'x1_5' }}>
          <Input
            id={'address2_field'}
            onChange={e =>
              handleChange({ target: { value: e, name: 'address2' } }) &&
              setAddress2(e)
            }
            value={address2}
            name="address2"
            placeholder={'Address 2'}
            isFullWidth={true}
            type="text"
          />
        </Div>
        <Div outset={{ bottom: 'x1_5' }} className={`${styles.contactField}`}>
          {isRegionsUnavailable && (
            <div style={{ margin: '15px 10px 0 0' }}>
              <Input
                id={'postal_code_field'}
                onChange={e =>
                  handleChange({ target: { value: e, name: 'postalCode' } }) &&
                  setPostalCode(e)
                }
                value={postalCode}
                message={{
                  type: 'danger',
                  content: postalCode ? '' : 'this field is required',
                }}
                name="postalCode"
                placeholder={'Postal Code'}
                type="text"
              />
            </div>
          )}
          <Input
            id={'city_field'}
            onChange={e =>
              handleChange({ target: { value: e, name: 'city' } }) && setCity(e)
            }
            value={city}
            message={{
              type: 'danger',
              content: city ? '' : 'this field is required',
            }}
            placeholder={'City'}
            type="text"
          />
          {!isRegionsUnavailable && (
            <Div
              id="state_field"
              outset={isMobile ? { vertical: 'x1_5' } : { horizontal: 'x1_5' }}
            >
              <Select
                selectedOptionIndex={
                  regions &&
                  [NO_STATE_SELECTION, ...regions]?.indexOf(state) !== -1
                    ? regions &&
                      [NO_STATE_SELECTION, ...regions]?.indexOf(state)
                    : 0
                }
                onChange={selectedIndex =>
                  updateState(
                    generatedStateOptions.map(option => option.label)[
                      selectedIndex
                    ] || NO_STATE_SELECTION,
                  )
                }
                alignment="center"
                options={generatedStateOptions}
                type="text"
                dropDownMaxHeight="250px"
                isDropDownAbove={true}
                focusColor={
                  state && state !== NO_STATE_SELECTION ? 'danger' : undefined
                }
                message={
                  state && state !== NO_STATE_SELECTION
                    ? undefined
                    : { type: 'danger', content: 'this field is required' }
                }
              />
            </Div>
          )}
          {!isRegionsUnavailable && (
            <Input
              id={'postal_code_field'}
              onChange={e =>
                handleChange({ target: { value: e, name: 'postalCode' } }) &&
                setPostalCode(e)
              }
              value={postalCode}
              message={{
                type: 'danger',
                content: postalCode ? '' : 'this field is required',
              }}
              name="postalCode"
              placeholder={'Postal Code'}
              type="text"
            />
          )}
        </Div>
        <div className={styles.contactField}>
          <Div
            outset={isMobile ? { vertical: 'x1_5' } : undefined}
            id={'country_field'}
            style={{ marginRight: '10px' }}
          >
            <Select
              selectedOptionIndex={
                countries?.indexOf(country) !== -1
                  ? countries?.indexOf(country)
                  : undefined
              }
              onChange={selectedIndex => {
                const country = countries?.[selectedIndex] || ''
                if (onCountryChange) {
                  onCountryChange(country)
                } else {
                  setCountry(country)
                }
              }}
              alignment="center"
              options={generatedCountryOptions}
              type="text"
              dropDownMaxHeight="250px"
              isDropDownAbove={true}
            />
          </Div>
          {disableCountryReason && (
            <div style={{ width: 40, display: 'flex', alignItems: 'center' }}>
              <Tooltip
                hoverIcon={'INFO'}
                message={disableCountryReason || 'Disabled right now'}
                position={'top'}
              />
            </div>
          )}
          <Input
            id={'company_field'}
            onChange={e =>
              handleChange({ target: { value: e, name: 'company' } }) &&
              setCompany(e)
            }
            value={company}
            placeholder={'Company'}
            type="text"
          />
        </div>
        {showPhoneNumber && (
          <Div outset={{ vertical: 'x_5' }}>
            <Input
              type="text"
              name={'phoneNumber'}
              value={phoneNumber}
              placeholder={'Phone'}
              isFullWidth={true}
              onChange={e => setPhoneNumber(e)}
              message={{
                type: 'danger',
                content: isPhoneInvalid
                  ? 'Phone numbers may only be numbers, spaces, and special characters: + ( ) -'
                  : '',
              }}
            />
          </Div>
        )}
        {shouldShowDates && (
          <div className={styles.dateFields}>
            <div style={{ display: 'flex' }}>
              <div id={'birthday_month_dropdown'}>
                <div style={{ marginTop: 20 }}>
                  <DateDropdown
                    label="Birthday"
                    initialValue={birthday}
                    onChange={newBirthday => setBirthday(newBirthday)}
                  />
                  {isBirthdayInvalid && (
                    <Text
                      type="footnote"
                      color="danger"
                      outset={{ top: '6px', left: 'x_5' }}
                    >
                      *invalid date
                    </Text>
                  )}
                </div>
                <div style={{ marginTop: 20 }}>
                  <DateDropdown
                    label="Anniversary"
                    initialValue={anniversary}
                    onChange={newAnniversary => setAnniversary(newAnniversary)}
                  />
                  {isAnniversaryInvalid && (
                    <Text
                      type="footnote"
                      color="danger"
                      outset={{ top: '6px', left: 'x_5' }}
                    >
                      *invalid date
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {showAddGroup && (
          <div className={styles.groupContainer}>
            <div style={{ width: 200 }}>
              <Select
                selectedOptionIndex={[
                  'Select a group',
                  ...(groups as Array<GroupFragment>).map(group => group.name),
                ]?.indexOf(selectedGroup)}
                options={[
                  { name: 'Select a group' },
                  ...(groups as Array<GroupFragment>),
                ]?.map(group => ({
                  value: group.name,
                  label: group.name,
                }))}
                onChange={(selectedIndex: number) =>
                  setSelectedGroup(
                    [
                      { name: 'Select a group' },
                      ...(groups as Array<GroupFragment>),
                    ][selectedIndex].name,
                  )
                }
                type="text"
                dropDownMaxHeight="250px"
                isDropDownAbove={true}
              />
            </div>

            <Button
              title="Create New Group"
              onClick={() => setShowAddGroupModal(true)}
              id={'add_group_btn'}
            />
          </div>
        )}

        <GroupAddCreateModal
          isOpen={showAddGroupModal}
          onCreateGroup={createGroup}
          initialMode="create"
          onClose={() => setShowAddGroupModal(false)}
          isModeToggleOn={false}
        />

        {showAddSpouse && (
          <div className={styles.header}>
            <Text type="body" weight="bold">
              Spouse
            </Text>
            {spouse.id.length === 0 && (
              <Button
                title="Add Spouse"
                onClick={() => setShowSpouseForm(true)}
              />
            )}
          </div>
        )}

        {spouse.id.length > 0 && (
          <ContactDetails
            contact={spouse}
            isExpandingAllowed={true}
            isExpanded={false}
            onDelete={resetSpouse}
            onEdit={() => setShowSpouseForm(true)}
            contactCategory="spouse"
          />
        )}

        {showSpouseForm && (
          <ContactForm
            isMinimal={true}
            onCancel={() => setShowSpouseForm(false)}
            onSave={onFinishSpouse}
            contact={spouse.id ? spouse : prefillData(spouse)}
          />
        )}

        {showAddChildren && (
          <div className={styles.header}>
            <Text type="body" weight="bold">
              Children
            </Text>

            <Button
              title="Add Child"
              onClick={() => setShowChildForm(true)}
              id={'add_child_btn'}
            />
          </div>
        )}

        {children.length > 0 &&
          children.map((child, childIndex) => (
            <ContactDetails
              key={child.id}
              contact={child}
              isExpanded={false}
              isExpandingAllowed={true}
              onDelete={() => deleteChild(childIndex)}
              onEdit={() => {
                setEditChildIndex(childIndex)
                setShowChildForm(true)
              }}
            />
          ))}

        {showChildForm && (
          <ContactForm
            isMinimal={true}
            onCancel={() => {
              setShowChildForm(false)
              setEditChildIndex(-1)
            }}
            onSave={onFinishChild}
            contact={
              editChildIndex >= 0
                ? children[editChildIndex]
                : prefillData(contactTemplate)
            }
          />
        )}

        {checkboxText && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setIsCheckboxChecked(x => !x)}
                checked={isCheckboxChecked}
              />
            }
            label={checkboxText}
          />
        )}
        {secondaryCheckboxText && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setIsSecondaryCheckboxChecked(x => !x)}
                checked={isSecondaryCheckboxChecked}
              />
            }
            label={secondaryCheckboxText}
          />
        )}
        {errorMessages && (
          <>
            {errorMessages.map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </>
        )}
        {!shouldHideActions && (
          <div
            style={{
              display: 'flex',
              justifyContent: alignAction,
              marginTop: 25,
              width: '100%',
            }}
          >
            {close && (
              <Button
                title={'Cancel'}
                type="shadow"
                onClick={close}
                disabled={isSaving}
              />
            )}
            <Button
              onClick={() => handleSubmit()}
              title={
                isSaving
                  ? 'Saving...'
                  : confirmButtonText
                  ? confirmButtonText
                  : !shouldShowDates
                  ? 'Save address'
                  : 'Save Contact'
              }
              id={'save_contact_btn'}
              disabled={isSaving || isInvalidRegion || isFormNotValid}
            />
          </div>
        )}
      </div>
    </Div>
  )
}

export default AddressForm
