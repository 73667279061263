import React from 'react'
// @src imports
import { Modal, Notification, Transition } from 'src/chrome'
import {
  Reminder,
  ShareNotification,
  ViewSentCard,
} from 'src/dashboard/components'
import {
  useActions,
  useEffect,
  useMutations,
  useReload,
  useState,
} from 'src/hooks'
import { AddOrderCardRoute } from 'src/orders/routes/AddOrderCardRoute'
// relative imports
import styles from './notifications.module.scss'
import suspenseBoundary from '../../../chrome/SuspenseBoundary/suspenseBoundaryHOC'
import {
  Button,
  ConfirmDialog,
  Dialog,
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
  Transition as QDSTransition,
} from '@sendoutcards/quantum-design-ui'
import { ContactInfo } from 'src/contacts/components'
import {
  queryKeyStore,
  useAcceptCampaignShare,
  useAcceptSharedContact,
  useAlerts,
  useDeleteCampaignShare,
  useDeleteContactShare,
  useDeleteReminder,
  useDenyNotification,
  useReceivedPendingMembershipInvites,
} from 'src/react_query'
import { useQueryClient } from '@tanstack/react-query'
import {
  Maybe,
  MembershipInviteFragment,
  NotificationType,
} from 'src/graphql/generated/graphql'
import LoadingIndicator from 'src/chrome/LoadingIndicator/LoadingIndicator'
import { getReceivedPendingMembershipInvites } from 'src/legacy_graphql'

const Notifications: React.FC = () => {
  const actions = useActions()
  const mutations = useMutations()
  const reloadQueries = useReload()
  const queryClient = useQueryClient()
  const [contactId, setContactId] = useState<string>()
  const [sentOrderId, setSentOrderId] = useState<string>()
  const [sentContactId, setSentContactId] = useState<string>()
  const [showCardModal, setShowCardModal] = useState(false)
  const [
    membershipInviteMutationStatus,
    setMembershipInviteMutationStatus,
  ] = useState<'accepting' | 'declining' | 'succeeded' | 'failed' | 'off'>(
    'off',
  )
  const [
    isMembershipInviteDialogOpen,
    setIsMembershipInviteDialogOpen,
  ] = useState(false)
  const [organizationNames, setOrganizationNames] = useState<string[]>([])

  const pendingMembershipInvitesQuery = useReceivedPendingMembershipInvites()
  const pendingMembershipInvites = pendingMembershipInvitesQuery.data

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAlerts(10, {
    notificationFilters: {
      isAcknowledge: false,
    },
  })

  const deleteReminderQuery = useDeleteReminder()
  const denyNotificationQuery = useDenyNotification()
  const acceptSharedContactQuery = useAcceptSharedContact()
  const acceptCampaignShareQuery = useAcceptCampaignShare()
  const deleteContactShareQuery = useDeleteContactShare()
  const deleteCampaignShareQuery = useDeleteCampaignShare()

  const contactShares = data?.pages.flatMap(
    page => page.paginatedContactShares.results,
  )
  const campaignShares = data?.pages.flatMap(
    page => page.paginatedCampaignShares.results,
  )
  const unfilteredReminders = data?.pages.flatMap(
    page => page.paginatedReminders.results,
  )
  const notifications = data?.pages.flatMap(
    page => page.paginatedNotifications.results,
  )
  const currentInviteOrganizationName =
    organizationNames.length > 0 ? organizationNames[0] : undefined

  const handleCreateOrder = async (
    contact: { id: string },
    reminderId: string,
  ) => {
    const {
      createOrder: { order },
    } = await mutations.createOrder({ order: { contacts: [contact.id] } })

    await mutations.updateReminder({
      reminder: { id: reminderId, orderId: order.id },
    })
    actions.openOrder(order.id, AddOrderCardRoute())
  }

  const handleViewCardHistory = (type?: Maybe<NotificationType>) => {
    actions.openCardHistory(type === 'REJECTED_CARDS' ? 'Rejected' : 'Held')
  }

  const handleViewSentOrder = (orderId: string, contactId: string) => {
    setSentOrderId(orderId)
    setSentContactId(contactId)
    setShowCardModal(true)
  }

  const postMembershipInviteActions = (action: 'succeeded' | 'failed') => {
    setMembershipInviteMutationStatus(action)
    setOrganizationNames(
      organizationNames.filter(
        value => value !== currentInviteOrganizationName,
      ),
    )
    setTimeout(() => {
      setMembershipInviteMutationStatus('off')
    }, 1000)
  }

  const handleAcceptMembershipInvite = async () => {
    if (currentInviteOrganizationName === undefined) return
    try {
      setIsMembershipInviteDialogOpen(false)
      setMembershipInviteMutationStatus('accepting')
      const {
        acceptMembershipInvite: { success: isSuccess },
      } = await mutations.acceptMembershipInvite({
        organizationName: currentInviteOrganizationName,
        doNotShowDefaultTransition: true,
      })
      postMembershipInviteActions(isSuccess ? 'succeeded' : 'failed')
    } catch (error) {
      postMembershipInviteActions('failed')
    }
  }

  const handleDeclineMembershipInvite = async () => {
    if (currentInviteOrganizationName === undefined) return
    try {
      setIsMembershipInviteDialogOpen(false)
      setMembershipInviteMutationStatus('declining')
      const {
        declineMembershipInvite: { success: isSuccess },
      } = await mutations.declineMembershipInvite({
        organizationName: currentInviteOrganizationName,
        doNotShowDefaultTransition: true,
      })
      postMembershipInviteActions(isSuccess ? 'succeeded' : 'failed')
    } catch (error) {
      postMembershipInviteActions('failed')
    }
  }

  const handleMembershipInviteTitle = (): string => {
    if (organizationNames.length <= 0) return ''
    return `You have been invited to the ${organizationNames[0]} organization`
  }

  const handleMembershipInviteDescription = (): string => {
    if (organizationNames.length <= 0) return ''
    return (
      `The ${organizationNames[0]} organization` +
      ` has invited you to an exclusive collection of card designs.` +
      ` Click Accept Invite below to join this organization's collection.`
    )
  }

  useEffect(() => {
    if (pendingMembershipInvites && pendingMembershipInvites.length > 0) {
      const invites = pendingMembershipInvites as MembershipInviteFragment[]
      setOrganizationNames(invites.flatMap(value => value.organizationName))
      setIsMembershipInviteDialogOpen(true)
    }
  }, [pendingMembershipInvites, setIsMembershipInviteDialogOpen])

  useEffect(() => {
    // Executed only once when leaving this component
    // to prevent cache to pull up old invites
    return () => {
      reloadQueries(getReceivedPendingMembershipInvites())

      queryClient.invalidateQueries(
        queryKeyStore.received_pending_membership_invites.detail,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (pendingMembershipInvitesQuery.isLoading || isLoading) {
    return <LoadingIndicator message={'Loading reminders...'} />
  }

  if (!unfilteredReminders) {
    return <></>
  }

  // Filter out reminders of type Project with no campaign.
  const reminders = unfilteredReminders.filter(
    reminder => !(reminder.type === 'PROJECT' && !reminder.campaign),
  )

  const shares = {
    campaigns: campaignShares,
    contacts: contactShares,
  }

  const hasShareNotifications =
    (shares.campaigns && shares.campaigns.length > 0) ||
    (shares.contacts && shares.contacts.length > 0)

  if (membershipInviteMutationStatus !== 'off') {
    const title =
      membershipInviteMutationStatus === 'accepting'
        ? `Accepting invitation...`
        : membershipInviteMutationStatus === 'declining'
        ? `Declining invitation...`
        : membershipInviteMutationStatus === 'succeeded'
        ? 'Success!'
        : membershipInviteMutationStatus === 'failed'
        ? 'Failed!'
        : ''
    const loadedStatus =
      membershipInviteMutationStatus === 'succeeded'
        ? 'success'
        : membershipInviteMutationStatus === 'failed'
        ? 'error'
        : undefined
    return (
      <Dialog
        isOpen={true}
        children={
          <div id="membership_invite_transition">
            <QDSTransition
              title={title}
              subtitle=""
              isLoading={!loadedStatus}
              loadedStatus={loadedStatus}
            />
          </div>
        }
      />
    )
  }

  if (isMembershipInviteDialogOpen) {
    return (
      <ConfirmDialog
        isOpen={true}
        title={handleMembershipInviteTitle()}
        description={handleMembershipInviteDescription()}
        accept={{
          onClick: () => {
            handleAcceptMembershipInvite()
          },
          title: 'Accept Invite',
        }}
        decline={{
          onClick: () => {
            handleDeclineMembershipInvite()
          },
          title: 'Decline Invite',
        }}
        primaryAction="accept"
      />
    )
  }

  const loadingMessage = deleteReminderQuery.isLoading
    ? 'Dismissing reminder...'
    : denyNotificationQuery.isLoading
    ? 'Denying notification...'
    : acceptSharedContactQuery.isLoading
    ? 'Accepting shared contact...'
    : acceptCampaignShareQuery.isLoading
    ? 'Accepting campaign share...'
    : deleteContactShareQuery.isLoading
    ? 'Denying contact share...'
    : deleteCampaignShareQuery.isLoading
    ? 'Denying campaign share...'
    : null

  if (loadingMessage) {
    return <LoadingIndicator message={loadingMessage} />
  }

  return (
    <div className={styles.notificationsWrapper}>
      {showCardModal && (
        <ViewSentCard
          onClose={() => setShowCardModal(false)}
          sentOrderId={sentOrderId}
          sentContactId={sentContactId}
        />
      )}
      {shares.campaigns?.map(notification => (
        <ShareNotification
          key={notification.id}
          notification={notification}
          title={'Shared Campaign'}
          sharedItemName={notification.campaign.name}
          dateShared={notification.dateShared}
          from={notification.fromName}
          onAccept={acceptCampaignShareQuery.mutate}
          onDeny={deleteCampaignShareQuery.mutate}
        />
      ))}
      {shares.contacts?.map(notification => (
        <ShareNotification
          key={notification.id}
          notification={notification}
          title={'Shared Contact'}
          sharedItemName={`${notification.contact.firstName} ${notification.contact.lastName}`}
          dateShared={notification.createdAt}
          from={`${notification.fromUser.firstName} ${notification.fromUser.lastName}`}
          onAccept={acceptSharedContactQuery.mutate}
          onDeny={deleteContactShareQuery.mutate}
        />
      ))}
      {!hasShareNotifications && !reminders.length && (
        <div className={styles.emptyTarget}>
          <Text type="body">No Messages...</Text>
        </div>
      )}
      {reminders.map(reminder => (
        <Reminder
          key={reminder.id}
          reminder={reminder}
          onSendCard={handleCreateOrder}
          onDismiss={deleteReminderQuery.mutate}
          openCampaign={actions.openCampaign}
          onOpenContact={id => setContactId(id)}
          openOrder={actions.openOrder}
          onOpenHistory={handleViewSentOrder}
        />
      ))}
      {notifications?.map(notification => (
        <Notification
          key={notification.id}
          message={notification.message ?? ''}
          onAccept={() => handleViewCardHistory(notification.type)}
          actionText={notification.linkText ?? 'Accept'}
          onClose={() => denyNotificationQuery.mutate(notification)}
        />
      ))}
      {contactId && (
        <Modal
          title=""
          onClose={() => setContactId(undefined)}
          bodyStyles={{
            maxHeight: 800,
          }}
          bodyChildren={
            <ContactInfo
              contactId={contactId}
              goToContact={id => setContactId(id)}
              isReadOnly={true}
            />
          }
        />
      )}
      <Spacer space="x9" />
      {hasNextPage && (
        <Flex width={'100%'} justifyContent="center">
          {isFetchingNextPage ? (
            <LoadingSpinner size="medium" />
          ) : (
            <Button onClick={() => fetchNextPage()} outlined={true}>
              Load More
            </Button>
          )}
        </Flex>
      )}
    </div>
  )
}

export default suspenseBoundary({
  component: Notifications,
  unresolved: <Transition message={'Loading notifications...'} />,
  failure: error => `Failed loading notifications: ${error}`,
})
