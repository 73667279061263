import React from 'react'

import { FilteredSearchBar } from 'src/chrome'
import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'
import { Memoized } from 'src/hooks/dependencies'
import { Button } from 'src/design_system/molecules/button/Button'
import styles from '../../containers/contactManager.module.scss'
import { Icon, Spacer } from '@sendoutcards/quantum-design-ui'

type Props = {
  onOpenGroups: () => void
  onAdd: () => void
  filters: SearchFilter[]
  setFilters: Memoized<(filters: SearchFilter[]) => void>
  clearSearch: Memoized<() => void>
  setTerms: Memoized<(terms: string) => void>
  onImport: () => void
  defaultValue?: string
  clearDefaultValue?: () => void
}

const ContactHeader = ({
  filters,
  onOpenGroups,
  onImport,
  setFilters,
  clearSearch,
  onAdd,
  setTerms,
  defaultValue = '',
  clearDefaultValue = () => {},
}: Props) => (
  <div className={styles.headerContainer}>
    <div className={styles.actionButtons}>
      <Button
        id={'import_csv_btn'}
        title={{
          content: 'Import Contacts',
          fontColor: '#e841b3',
          fontSize: '14px',
        }}
        padding="8px 25px"
        onClick={onImport}
        fill="#e841b3"
        borderRadius="10px"
        isOutlined={true}
      >
        <Icon name="contact" size="xSmall" primaryColor="#e841b3" />
      </Button>
      <Spacer orientation="horizontal" space="x2" />
      <Button
        id="view_groups_btn"
        title={{
          content: 'View Groups',
          fontColor: '#fff',
          fontSize: '14px',
        }}
        padding="8px 25px"
        onClick={onOpenGroups}
        fill="#e841b3"
        borderRadius="10px"
      >
        <Icon name="filterBy" size="xSmall" primaryColor="#fff" />
      </Button>
    </div>
    <div className={styles.searchBar}>
      <FilteredSearchBar
        filters={filters}
        className={styles.searchFilter}
        setTerms={setTerms}
        setFilters={setFilters}
        clearSearch={clearSearch}
        autocomplete={'off'}
        defaultValue={defaultValue}
        clearDefaultValue={clearDefaultValue}
      />
    </div>
    <div className={styles.addButton}>
      <Button
        id="add_new_contact_btn"
        title={{
          content: 'Add Contact',
          fontColor: '#fff',
          fontSize: '14px',
        }}
        padding="8px 25px"
        onClick={onAdd}
        fill="#e841b3"
        borderRadius="10px"
      >
        <Icon name="add" size="xSmall" primaryColor="#fff" />
      </Button>
    </div>
  </div>
)

export default ContactHeader
