import { Maybe, Operation } from '../../../__generated__/globalTypes'

import {
  ContactFragment,
  contactFragmentString,
} from '../../fragments/__generated__/ContactFragment'

import { optionalYearDateFragmentString } from '../../fragments/__generated__/OptionalYearDateFragment'

import getPaginatedContactsRawString from '../GetPaginatedContacts.graphql'

const getPaginatedContactsString = [
  getPaginatedContactsRawString,
  contactFragmentString,
  optionalYearDateFragmentString,
].join('\n\n')

export type GetPaginatedContactsData = {
  paginatedContacts: {
    contacts: ContactFragment[]
    count: number
  }
}

export type GetPaginatedContactsVariables = {
  search?: Maybe<string>
  offset: number
  limit: number
  contacts?: Maybe<string[]>
  groups?: Maybe<string[]>
  birthdayStartDate?: Maybe<string>
  birthdayEndDate?: Maybe<string>
  anniversaryStartDate?: Maybe<string>
  anniversaryEndDate?: Maybe<string>
  showSecondaryContacts?: Maybe<boolean>
  hasNoGroup?: Maybe<boolean>
  companyName?: Maybe<string>
}

export type GetPaginatedContacts = Operation<
  GetPaginatedContactsData,
  GetPaginatedContactsVariables
>

export const GetPaginatedContacts = ({
  search,
  offset,
  limit,
  contacts,
  groups,
  birthdayStartDate,
  birthdayEndDate,
  anniversaryStartDate,
  anniversaryEndDate,
  showSecondaryContacts,
  hasNoGroup,
  companyName,
}: GetPaginatedContactsVariables): GetPaginatedContacts => ({
  query: getPaginatedContactsString,
  variables: {
    search,
    offset,
    limit,
    contacts,
    groups,
    birthdayStartDate,
    birthdayEndDate,
    anniversaryStartDate,
    anniversaryEndDate,
    showSecondaryContacts,
    hasNoGroup,
    companyName,
  },
})
