import React from 'react'
// @src imports
import { DefaultError, Icon } from 'src/chrome'
import { getPaperTypes } from 'src/app/constants'
import { CardPanelView } from 'src/orders/components'
import { useState } from 'src/hooks'
import * as SendDelayOptions from 'src/orders/sendDelayOptions'

import styles from './campaignPreview.module.scss'
import CardThumbnailTile from 'src/campaign_store/components/CardThumbnailTile/CardThumbnailTile'
import suspenseBoundary from 'src/chrome/SuspenseBoundary/suspenseBoundaryHOC'
import { CardPaperType, CardType } from 'src/graphql/generated/graphql'
import { useCampaign } from 'src/react_query'
import { LoadingSpinner } from '@sendoutcards/quantum-design-ui'

interface Props {
  campaignId: string
  onClose: () => void
}

const CampaignPreview: React.FC<Props> = props => {
  const { campaignId, onClose } = props
  const { data: campaign, isLoading } = useCampaign({ id: campaignId })

  const [activeLine, setActiveLine] = useState(campaign?.lines[0])
  const lines = campaign?.lines
  const name = campaign?.name
  const paperTypes = getPaperTypes(activeLine?.card?.type ?? CardType.Postcard)
  const paperType = paperTypes.find(
    paperType =>
      paperType.value === (activeLine?.card?.paperType ?? CardPaperType.Std),
  )

  if (isLoading) {
    return <LoadingSpinner size="medium" />
  }

  // TODO: React Query Refactor, should we throw if no lines/active line?
  if (!lines || lines?.length === 0 || !activeLine) return null // TODO

  return (
    <div className={styles.campaignPreviewContainer}>
      <div className={styles.modal}>
        <div className={styles.campaignHeader}>
          <div className={styles.sideScrollContainer}>
            <div className={styles.scrollable}>
              {lines.map((line, index) => (
                <CardThumbnailTile
                  key={line.card?.id ?? index}
                  line={line}
                  activeLine={activeLine}
                  isCloseButtonEnabled={false}
                  onCardSelect={() => {
                    if (activeLine.card?.id !== line.card?.id) {
                      setActiveLine(line)
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 15,
              cursor: 'pointer',
              zIndex: 100,
            }}
            onClick={() => onClose()}
          >
            <Icon icon={'CLOSE'} size={18} color={'#6f8394'} />
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div className={styles.cardAndDetailsContainer}>
            <div className={styles.previewContainer}>
              {activeLine?.card && (
                <div className={styles.card}>
                  <CardPanelView
                    card={{
                      ...activeLine.card,
                      panels: activeLine.card.minimalPanels,
                    }}
                    sliderMaxContainerHeight={444}
                    shouldRotateBackPanels={false}
                  />
                </div>
              )}
            </div>
            <div className={styles.rightPanel}>
              <div className={styles.content}>
                <div className={styles.campaignDetails}>
                  <h4 style={{ fontSize: '1rem' }}>{name}</h4>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={styles.subHeader}>
                      <Icon icon={'TWOPANEL'} size={23} color={'#F171CC'} />
                      {lines.length} {`Card${lines.length !== 1 ? 's' : ''}`}
                    </div>
                  </div>
                </div>
                <div className={styles.cardDetails}>
                  <h4 style={{ color: 'rgb(115, 115, 115)', fontSize: 16 }}>
                    Card
                  </h4>
                  {activeLine && (
                    <div className={styles.cardDescription}>
                      <span>{activeLine.name}</span>
                      <span>
                        Send Delay:{' '}
                        {SendDelayOptions.ribbonDescription(
                          activeLine.sendDelay,
                        )}
                      </span>
                      {activeLine.card?.sendableCard && (
                        <p style={{ fontSize: '0.9rem' }}>
                          {activeLine.card.sendableCard.title}
                        </p>
                      )}
                      {paperType?.icon && (
                        <span>
                          Paper Type:{' '}
                          <Icon
                            icon={paperType.icon}
                            size={paperType.iconSize}
                            color={'#F171CC'}
                            viewBox={paperType.viewBox}
                          />{' '}
                          {paperType.label}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.actions} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modalWindow} onClick={() => onClose()} />
    </div>
  )
}

export default suspenseBoundary({
  component: CampaignPreview,
  unresolved: '',
  failure: DefaultError,
})
