import React from 'react'
import CardPreview from '../../../chrome/CardPreview/CardPreview'
import {
  Div,
  Flex,
  HStack,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import Price from 'src/chrome/Price/Price'
import { CardLineItemProps } from '../CardLineItem/CardLineItem'
import { useSelector, useState } from 'src/hooks'
import { ReplaceCardDialog } from '../ReplaceCardDialog/ReplaceCardDialog'
import { getCardType, mapPanelSize } from 'src/orders/helpers'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'

const OrderPreviewOption: React.FC = ({ children }) => (
  <Flex
    style={{ borderRadius: 40 }}
    inset={{ horizontal: 'x2' }}
    minHeight="34px"
    backgroundColor="background"
    minWidth="60px"
    alignItems="center"
  >
    {children}
  </Flex>
)

export const OrderPreviewCardLineItem = (
  props: Omit<
    CardLineItemProps,
    'sendDelay' | 'onUpdatePaperType' | 'onToggleSendDelay' | 'lineIndex'
  >,
) => {
  const { card, onEditCard, onReplaceCard, cost } = props
  const width = card.isHorizontal ? 140 : 102
  const height = card.isHorizontal ? 102 : 140

  const previewUrls = card.panels.map(panel => {
    return panel.previewUrl
  })
  const [
    shouldShowReplaceCardDialog,
    setShouldShowReplaceCardDialog,
  ] = useState(false)

  const onView = () => {
    onEditCard?.(card)
  }
  const isSmallMobile = useSelector(state => state.window.width <= 426)

  const handleReplaceCard = () => {
    onReplaceCard?.(card.id)
  }

  return (
    <Flex width="100%" flexDirection="column">
      <Div display="flex">
        <Flex justifyContent="flex-start">
          <CardPreview
            width={width}
            height={height}
            panelUrls={previewUrls}
            isHorizontal={card.isHorizontal}
            onView={onView}
          />
        </Flex>
        <Spacer space="x2" orientation="horizontal" />
        <Flex width="100%" flexWrap="wrap">
          <Flex flexDirection="column" width="100%">
            <Flex alignItems="center" justifyContent="space-between">
              <Text
                type={'body'}
                weight="bold"
                color="primaryHeading"
                content={card.detailedSendableCard?.title}
                inset={{ left: 'x_5' }}
                lineHeight={1.5}
              />
              <Div
                id="replace_card_from_order_preview"
                cursor="pointer"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={'32px'}
                height="32px"
                borderColor={'#c2c2c2'}
                borderWidth="default"
                borderStyle="solid"
                borderRadius="small"
                inset={width <= 406 ? { horizontal: 'x2' } : 'x0'}
                onClick={() => setShouldShowReplaceCardDialog(true)}
              >
                <Div
                  id="replace_card_icon"
                  display="flex"
                  height="fit-content"
                  inset={{ left: 'x_5' }}
                >
                  <Icon name="replace" size={26} color={'#c2c2c2'} />
                </Div>
              </Div>
            </Flex>
            <Flex flexDirection="row" columnGap="x1_5" inset={{ left: 'x_5' }}>
              <Text
                type="footnote"
                weight="semiBold"
                color="primaryHeading"
                content={`Type:`}
                whiteSpace="nowrap"
                lineHeight={1.2}
                outset={card.isHorizontal ? { bottom: 'x1' } : { bottom: 'x2' }}
              />
              <Text
                type="footnote"
                weight="regular"
                color="primaryHeading"
                content={getCardType(card)}
                whiteSpace="nowrap"
                lineHeight={1.2}
                outset={card.isHorizontal ? { bottom: 'x1' } : { bottom: 'x2' }}
              />
            </Flex>
            <Flex inset={{ bottom: 'x1' }} flexDirection="row" columnGap="x1_5">
              <OrderPreviewOption>
                <HStack justify="center" gap="x_5">
                  <Text
                    type={isSmallMobile ? 'footnote' : 'caption'}
                    content="S:"
                  />
                  <Text
                    type={isSmallMobile ? 'footnote' : 'caption'}
                    content={mapPanelSize(card)}
                    weight="bold"
                  />
                </HStack>
              </OrderPreviewOption>
              <OrderPreviewOption>
                <HStack justify="center" gap="x_5">
                  <Text
                    type={isSmallMobile ? 'footnote' : 'caption'}
                    content="P:"
                  />
                  <Text
                    type={isSmallMobile ? 'footnote' : 'caption'}
                    content={getCardType(card)}
                    weight="bold"
                  />
                </HStack>
              </OrderPreviewOption>
            </Flex>
            <Price
              inset={{ top: 'x0', left: 'x_5' }}
              direction="row"
              price={cost.price}
              discountPrice={cost.discountPrice}
              discountMessage={cost.discountMessage}
            />
          </Flex>
        </Flex>
      </Div>
      {shouldShowReplaceCardDialog && (
        <ReplaceCardDialog
          isOpen={shouldShowReplaceCardDialog}
          onAccept={handleReplaceCard}
          onDecline={() =>
            setShouldShowReplaceCardDialog(!shouldShowReplaceCardDialog)
          }
          setIsOpen={setShouldShowReplaceCardDialog}
        />
      )}
    </Flex>
  )
}
