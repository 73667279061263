// @src imports
import { Store } from 'src/redux/store'
// mutations
import { CreateContactInput, Operation } from 'src/legacy_graphql'
import * as Result from '../utils/Result'
import { operationName } from 'src/legacy_graphql/utils'
import fetchRetry from 'fetch-retry'
import { Set } from 'immutable'
import { saveAs } from 'file-saver'
import moment from 'moment'
import LogRocket from 'src/logrocket/logrocket'

const fetch = fetchRetry(window.fetch, {
  retries: 3,
  retryOn: (attempt: number, error: Error | null, response: Response | null) =>
    error?.message === 'Failed to fetch' || response?.status === 503,
  retryDelay: (attempt: number) =>
    Math.pow(2, attempt) * (400 + Math.random() * 400), // ~600, ~1200, ~2400}
})

export const baseURL = process.env.REACT_APP_API_URL || ''

export const LEGACY_SITE_URL =
  process.env.REACT_APP_OLD_WEBSITE_URL || 'https://www.sendoutcards.com'

const getHeaders = (isFormData: boolean = false) => {
  const token = Store.getState().user.controlData.token
  const sessionURL =
    process.env.NODE_ENV === 'production' && LogRocket.sessionURL
  return {
    // eslint-disable-next-line no-useless-computed-key
    ...(isFormData ? {} : { ['Content-Type']: 'application/json' }),
    ...(token ? { Authorization: `Token ${token}` } : {}),
    ...(sessionURL ? { 'X-LogRocket-URL': sessionURL } : {}),
  }
}

const fetchResult = async <Data>(
  {
    query,
    method,
    body,
    signal,
  }: {
    query: string
    method: 'GET' | 'POST'
    body: RequestInit['body']
    signal: AbortSignal
  },
  completion: (result: Result.Result<Data>) => void,
) => {
  try {
    const response = await fetch(`${baseURL}/graphql${query}`, {
      method,
      headers:
        method === 'POST' ? getHeaders(body instanceof FormData) : undefined,
      body,
      signal,
    })
    if (response.status !== 200) {
      logGraphQLResponseError(query, response)
      switch (response.status) {
        case 404:
          throw Error(`Response ${response.status}\nNot Found`)
        case 401:
          throw Error(`Response ${response.status}\nUnauthorized`)
        case 410:
          throw Error(`Response ${response.status}\nToo Many Requests`)
        case 500:
          throw Error(`Response ${response.status}\nInternal Server Error`)
        case 503:
          throw Error(`Response ${response.status}\nService Unavailable`)
        default:
          throw Error(`Response ${response.status}`)
      }
    }

    const { data, errors } = await response.json()
    logGraphQLResponseSuccess(query, data, errors)
    if (data) {
      completion(Result.success(data))
    } else if (errors) {
      throw Error(
        errors.map((error: { message: string }) => error.message).join('\n'),
      )
    } else {
      throw Error('No data or errors')
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.name !== 'AbortError') {
        console.error(error)
        completion(Result.failure(error))
      }
    }
  }
}

export const performOperation = <Data, Variables = undefined>(
  operation: Operation<Data, Variables>,
  completion: (result: Result.Result<Data>) => void,
  hitCDNCache: boolean = false,
  isFormData: boolean = false,
) => {
  const abortController = new AbortController()

  const query =
    '?' +
    Object.entries(operation)
      .filter(([, value]) => value)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(`${value}`)}`,
      )
      .join('&')

  fetchResult(
    {
      query: hitCDNCache ? query : '',
      method: hitCDNCache ? 'GET' : 'POST',
      body: hitCDNCache
        ? undefined
        : isFormData
        ? asFormData(operation)
        : JSON.stringify(operation),
      signal: abortController.signal,
    },
    completion,
  )

  if (process.env.NODE_ENV === 'development') {
    logGraphiQLUrl(operation)
  }

  return { cancel: () => abortController.abort() }
}

const logGraphiQLUrl = (operation: Operation<unknown, unknown>) => {
  if (process.env.REACT_APP_USE_GQL_DEBUG === 'true') {
    const hash = Object.entries({
      ...operation,
      headers: getHeaders(),
    })
      .filter(([, value]) => value)
      .map(
        ([key, value]) =>
          `${key}=${encodeURIComponent(
            typeof value === 'string' ? value : JSON.stringify(value),
          )}`,
      )
      .join('&')
    const regex = /www(?!\.prod)/
    const url = baseURL.replace(regex, 'www.prod')
    console.log(
      '[graphql-debugger-request]',
      operationName(operation),
      `${url}/graphiql#${hash}`,
    )
  }
}

const logGraphQLResponseError = (query: string, response: Response) => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_USE_GQL_RESPONSE_DEBUG === 'true'
  ) {
    console.log(
      '[graphql-debugger-response]',
      query,
      response.status,
      response.text(),
    )
  }
}

const logGraphQLResponseSuccess = <Data>(
  query: string,
  data: Data,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
) => {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_USE_GQL_RESPONSE_DEBUG === 'true'
  ) {
    console.log(
      '[graphql-debugger-response]',
      query,
      data,
      error ? error : 'no errors',
    )
  }
}

export const perform = <Data, Variables = undefined>(
  operation: Operation<Data, Variables>,
  hitCDNCache: boolean = false,
  isFormData: boolean = false,
): Promise<Data> => {
  let mutableResolve: (value: Data | PromiseLike<Data>) => void
  let mutableReject: (reason: unknown) => void

  const promise = new Promise<Data>((resolve, reject) => {
    mutableResolve = resolve
    mutableReject = reject
  })

  performOperation(
    operation,
    result =>
      result.match(
        value => mutableResolve(value),
        error => mutableReject(error),
      ),
    hitCDNCache,
    isFormData,
  )

  return promise
}

const asFormData = <Data, Variables = undefined>(
  operation: Operation<Data, Variables>,
) => {
  const data = new FormData()
  data.append('query', operation.query)

  if (operation.variables) {
    Object.entries(operation.variables).forEach(([key, value]) => {
      if (value instanceof Blob) {
        data.append(key, value)
      } else {
        data.append(key, value as string)
      }
    })
  }

  return data
}

export const createContactInput = (
  contact: Omit<CreateContactInput, 'companyName'> & {
    company?: string | null
  },
): CreateContactInput => {
  const { company, ...new_contact } = contact
  return {
    ...new_contact,
    companyName: company,
  }
}

export const exportContactsCsvFrom = async (
  source: 'contacts' | 'groups',
  uuids: Set<string>,
) => {
  const body = new FormData()
  body.append(source, uuids.toArray().join())

  const response = await fetch(`${baseURL}/contact/export/csv/`, {
    method: 'POST',
    headers: getHeaders(true),
    body,
  })

  const data = await response.blob()
  saveAs(data, `contacts-${moment().format('YYYY-MM-DD')}.csv`)
}
