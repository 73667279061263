import React from 'react'

import {
  ContactFragment,
  getPaginatedContacts,
  getPaginatedGroups,
  GroupFragment,
} from 'src/legacy_graphql'

import { SearchFilter, SearchFilterType } from './types'
import TextInput from './lib/TextInput'
import ListFilterInput from './lib/ListFilterInput'
import DateRangeInput from './lib/DateRangeInput'

import { useCallback, useEffect, useQueries, useState } from 'src/hooks'
import CompanyFilterInput from './lib/CompanyFilterInput'
import { Memoized } from 'src/hooks/dependencies'
import { Icon } from '@sendoutcards/quantum-design-ui'

import styles from './filteredSearchBar.module.scss'

const filterCountStyles = {
  right: -5,
  top: 10,
  position: 'absolute',
} as const

interface Props {
  filters: SearchFilter[]
  setFilters: Memoized<(filters: SearchFilter[]) => void>
  setTerms: Memoized<(terms: string) => void>
  clearSearch: Memoized<() => void>
  className?: string
  inputClassName?: string
  style?: React.CSSProperties
  autocomplete?: 'on' | 'off'
  defaultValue?: string
  clearDefaultValue?: () => void
}

const FilteredSearchBar: React.FC<Props> = props => {
  const {
    filters,
    className,
    inputClassName,
    style,
    setTerms,
    setFilters,
    clearSearch,
    autocomplete,
    defaultValue,
    clearDefaultValue,
  } = props

  const [editedFilter, setEditedFilter] = useState<SearchFilter>()
  const [groupId, setGroupId] = useState('')
  const [groupName, setGroupName] = useState('')
  const [groupContacts] = useQueries(
    groupId ? getPaginatedContacts({ groups: [groupId], page: 1 }) : undefined,
  )

  const clearAll = () => {
    setEditedFilter(undefined)
    clearSearch()
  }

  const updateFilter = useCallback(
    <T extends SearchFilter>(filter: T, update: Partial<T>) => {
      const index = filters.findIndex(x => x === filter)
      if (index < 0) {
        throw new Error(`filter not found: ${filter.name}`)
      }
      setFilters(
        filters.filter(x => x !== filter).concat([{ ...filter, ...update }]),
      )
      setGroupId('')
      setGroupName('')
    },
    [filters, setFilters],
  )

  const deleteFilter = (target: SearchFilter) =>
    updateFilter(target, { value: undefined })

  const definedFilters = filters.filter(filter => filter.value !== undefined)

  useEffect(() => {
    if (editedFilter && groupContacts && groupContacts.count > 0) {
      updateFilter(editedFilter, {
        value: { contacts: groupContacts.results, groupName: groupName },
      })
      setEditedFilter(undefined)
    }
  }, [groupId, groupContacts, groupName, editedFilter, updateFilter])

  return (
    <div className={`${styles.searchField} ${className}`} style={style}>
      <div
        className={
          inputClassName ? `${styles.search} ${inputClassName}` : styles.search
        }
      >
        {definedFilters.length ? (
          <span className={styles.filterIcon}>
            <Icon name="filter" primaryColor="#ff5689" size="xSmall" />

            <span style={filterCountStyles}>{definedFilters.length}</span>
          </span>
        ) : (
          <span className={styles.searchIcon}>
            <Icon name="search" primaryColor="#ff5689" size="xSmall" />
          </span>
        )}

        {(() => {
          if (editedFilter) {
            switch (editedFilter.type) {
              case SearchFilterType.CONTACT:
                return (
                  <ListFilterInput<ContactFragment>
                    deleteFilter={deleteFilter}
                    filterName={editedFilter.name}
                    filters={filters}
                    onClear={clearAll}
                    onCancel={() => setEditedFilter(undefined)}
                    getKey={item => item.id}
                    getLabel={item => `${item.firstName} ${item.lastName}`}
                    getQuery={search =>
                      getPaginatedContacts({ search, page: 1 })
                    }
                    onSave={item => {
                      updateFilter(editedFilter, {
                        value: (editedFilter.value ?? []).concat([item]),
                      })
                      setEditedFilter(undefined)
                    }}
                  />
                )
              case SearchFilterType.COMPANY:
                return (
                  <CompanyFilterInput
                    deleteFilter={deleteFilter}
                    filters={filters}
                    onClear={clearAll}
                    resetEditedFilter={() => setEditedFilter(undefined)}
                    updateFilter={updateFilter}
                    filter={editedFilter}
                    autocomplete={autocomplete}
                  />
                )
              case SearchFilterType.GROUP:
                return (
                  <ListFilterInput<GroupFragment>
                    deleteFilter={deleteFilter}
                    filterName={editedFilter.name}
                    filters={filters}
                    onClear={clearAll}
                    onCancel={() => setEditedFilter(undefined)}
                    getKey={item => item.id}
                    getLabel={item => `${item.name}`}
                    getQuery={search => getPaginatedGroups({ search, page: 1 })}
                    onSave={item => {
                      updateFilter(editedFilter, {
                        value: (editedFilter.value ?? []).concat([item]),
                      })
                      setEditedFilter(undefined)
                    }}
                    autocomplete={'off'}
                  />
                )

              case SearchFilterType.GROUP_HISTORY:
                return (
                  <ListFilterInput<GroupFragment>
                    deleteFilter={deleteFilter}
                    filterName={editedFilter.name}
                    filters={filters}
                    onClear={clearAll}
                    onCancel={() => setEditedFilter(undefined)}
                    getKey={item => item.id}
                    getLabel={item => `${item.name}`}
                    getQuery={search => getPaginatedGroups({ search, page: 1 })}
                    autocomplete={'off'}
                    setGroupId={setGroupId}
                    setGroupName={setGroupName}
                  />
                )

              case SearchFilterType.DATERANGE:
                return (
                  <DateRangeInput
                    deleteFilter={deleteFilter}
                    filter={editedFilter}
                    filters={filters}
                    onClear={clearAll}
                    updateFilter={updateFilter}
                    onClose={() => setEditedFilter(undefined)}
                  />
                )
            }

            return null
          } else {
            return (
              <TextInput
                filters={filters}
                deleteFilter={deleteFilter}
                onClear={clearAll}
                setEditedFilter={setEditedFilter}
                updateFilter={updateFilter}
                onSearch={terms => setTerms(terms)}
                autocomplete={autocomplete}
                defaultValue={defaultValue}
                clearDefaultValue={clearDefaultValue}
              />
            )
          }
        })()}
      </div>
    </div>
  )
}

export default FilteredSearchBar
