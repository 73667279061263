import React from 'react'
// @src imports
import { useActions, useSelector } from 'src/hooks'
import {
  PromptingsCoachDiscountFragment,
  UserType,
} from 'src/graphql/generated/graphql'
import { Div, Flex, Text } from '@sendoutcards/quantum-design-ui'
import CoachingEventCard from 'src/coaching/components/CoachingEventCard/CoachingEventCard'
import { CoachingBadgeType } from 'src/coaching/svg/coachingBadgeSVGs'
import { navigateAndScroll } from 'src/pricing_page/utils'
import {
  useAccountQuery,
  usePromptingsCoachDiscounts,
  usePromptingsEventDates,
} from 'src/react_query'
import styles from '../promptingEvent.module.scss'

type EventType = {
  title: string
  date: string
  location: string
  badge: CoachingBadgeType
  code?: { title: string; value: string }
  action?: {
    title: string
    onClick?: () => void
    onBydesignRedirect?: string
  }
}

const PromptingsUpcomingEventsComponent = () => {
  const actions = useActions()
  const isMobile = useSelector(state => state.window.width <= 474)

  const eventDates = usePromptingsEventDates()
  const promptingsDiscounts = usePromptingsCoachDiscounts()

  const { data: account } = useAccountQuery()
  const isGSA = account?.type === UserType.Gsa

  const handleNavigation = (hash: string) => {
    actions.openPricing()
    navigateAndScroll(hash)
  }

  const getDiscount = (eventbriteGroupId: string) => {
    if (promptingsDiscounts.data) {
      const mutableDiscount = promptingsDiscounts.data
      return mutableDiscount
        .sort((a, b) => {
          const dateA = new Date(a.expirationDatetime)
          const dateB = new Date(b.expirationDatetime)
          if (dateA < dateB) {
            return -1
          }
          if (dateA > dateB) {
            return 1
          }
          return 0
        })
        .find(disc => {
          if (!disc.isUsed && disc.eventbriteGroupId === eventbriteGroupId)
            return disc
          else return undefined
        })
    } else {
      return undefined
    }
  }

  const getDiscountTitle = (discount: PromptingsCoachDiscountFragment) => {
    if (discount.percentOff && discount.percentOff === 100) {
      return 'TICKET CODE:'
    } else {
      return 'DISCOUNT CODE:'
    }
  }

  const seminarDiscount = getDiscount('651379')
  const prosperityDiscount = getDiscount('651399')

  const events: EventType[] = [
    {
      title: 'PROMPTINGS SEMINAR',
      date:
        eventDates.isLoading || promptingsDiscounts.isLoading
          ? 'Loading Event Dates...'
          : eventDates.data?.promptingsSeminarEventDate?.content ??
            'Could not retrieve event date',
      location: 'SALT LAKE CITY',
      badge: 'seminar',
      code: isGSA
        ? {
            title: 'TICKET CODE:',
            value: 'PS-2024',
          }
        : seminarDiscount
        ? {
            title: getDiscountTitle(seminarDiscount),
            value: seminarDiscount?.code,
          }
        : undefined,
      action: {
        title: 'Event Tickets',
        onClick: () => {
          window.open('http://www.promptingsseminar.com/')
        },
      },
    },
    {
      title: 'PROMPTINGS TO PROSPERITY',
      date:
        eventDates.isLoading || promptingsDiscounts.isLoading
          ? 'Loading Event Dates...'
          : eventDates.data?.promptingsProsperityEventDate?.content ??
            'Could not retrieve event date',
      location: 'SALT LAKE CITY',
      badge: 'promptingsProsperity',
      code: prosperityDiscount
        ? {
            title: getDiscountTitle(prosperityDiscount),
            value: prosperityDiscount.code,
          }
        : undefined,
      action: {
        title: 'Event Tickets',
        onClick: () => handleNavigation('Academy'),
      },
    },
  ]

  return (
    <Flex
      flexDirection="column"
      outset={{ vertical: 'x1' }}
      position="relative"
      width="100%"
    >
      <Text
        type="body"
        style={{ color: '#C0C0C0' }}
        content="UPCOMING EVENTS"
        weight="bold"
        outset={{ left: 'x3' }}
      />
      <Div
        inset={isMobile ? '1.5rem 1rem' : '1.5rem 0'}
        className={styles.cardList}
      >
        {events &&
          events.map((event, index) => (
            <CoachingEventCard
              key={`${event.code} ${index}`}
              badge={event.badge}
              title={event.title}
              date={event.date}
              location={event.location}
              code={event.code ?? undefined}
              action={event.action ?? undefined}
            />
          ))}
      </Div>
    </Flex>
  )
}

export default PromptingsUpcomingEventsComponent
