import React from 'react'
import {
  Div,
  Flex,
  Icon,
  Select,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import CardPreview from '../../../chrome/CardPreview/CardPreview'
import CardItemOption from '../../../chrome/CardItemOption/CardItemOption'
import Price from '../../../chrome/Price/Price'
import { useSelector, useState } from 'src/hooks'
import { PartialLine } from 'src/helpers/multitouch'
import {
  getCardType,
  getPaperOptions,
  getSendDelayText,
  onPaperTypeSelected,
} from 'src/orders/helpers'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'
import { QuantityCounter } from '../QuantityCounter/QuantityCounter'
import MobileCardLineItem from './components/MobileCardLineItem'
import { Icon as DSIcon } from 'src/design_system/atoms/icons/components/Icon'
import { ReplaceCardDialog } from '../ReplaceCardDialog/ReplaceCardDialog'
import { ImageErrorDisclaimer } from './components/ImageErrorDisclaimer'
import {
  CardFragment,
  CardPaperType,
  CardSendType,
  SendDelayFragment,
} from 'src/graphql/generated/graphql'

export type CardCosts = {
  price: string
  discountPrice?: string
  discountMessage?: string
}

export type CardLineItemCallbacksType = {
  onEditCard?: (card: CardFragment) => void
  onUpdatePaperType?: (paperType: CardPaperType, card: CardFragment) => void
  onToggleSendDelay?: () => void
  onRemoveCard?: (line?: PartialLine) => void
  onReplaceCard?: (cardId: string) => void
}

export type CardLineItemProps = {
  card: CardFragment
  sendDelay: SendDelayFragment
  cost: CardCosts
  lineIndex: number
  order?: OrderWithPartialLines
  quantity: number
  didFlattenFail: boolean
} & CardLineItemCallbacksType

const CardLineItem: React.FC<CardLineItemProps> = props => {
  const {
    card,
    sendDelay,
    cost,
    onEditCard,
    order,
    onUpdatePaperType,
    onToggleSendDelay,
    onReplaceCard,
    lineIndex,
    quantity,
    didFlattenFail,
  } = props

  const { width } = useSelector(state => state.window)
  const isMobile = width <= 1233

  const styles = {
    detail: {
      display: 'flex',
      alignItems: 'center',
    },
    settings: {
      display: 'flex',
      marginLeft: 'auto',
    },
    cost: {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 768px)': {
        gridArea: 'cost',
        display: 'inline-grid',
        justifyItems: 'center',
      },
    },
    price: {
      minWidth: '82px',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
  }

  const [
    shouldShowReplaceCardDialog,
    setShouldShowReplaceCardDialog,
  ] = useState(false)

  const previewUrls = card.panels.map(panel => {
    return panel.previewUrl
  })

  const selectedPaperTypeOpt = card.paperUpgradeCosts
    .filter(paper => paper.value !== 'STA')
    .findIndex(paper => paper.value === card.paperType)

  const onView = () => {
    onEditCard?.(card)
  }

  const handleReplaceCard = () => {
    onReplaceCard?.(card.id)
  }

  const handleUpdatePaper = (optionsIndex: number) => {
    onPaperTypeSelected(card, optionsIndex, onUpdatePaperType)
  }
  return (
    <>
      {!isMobile ? (
        <>
          <Div css={styles.container}>
            <Div css={styles.detail}>
              <CardPreview
                panelUrls={previewUrls}
                isHorizontal={card.isHorizontal}
                onView={onView}
                didFlattenFail={didFlattenFail}
              />
              <Spacer space="x2_5" orientation="horizontal" />
              <Div>
                <Text
                  type="largeBody"
                  weight="semiBold"
                  color="primaryHeading"
                  content={card.detailedSendableCard?.title ?? 'Build Your Own'}
                  whiteSpace="nowrap"
                />
                <Flex inset={{ bottom: 'x1' }}>
                  <Div
                    borderRadius="default"
                    inset={{ horizontal: 'x_75' }}
                    backgroundColor="background"
                  >
                    <Text
                      color="primaryHeading"
                      weight="semiBold"
                      type="caption"
                      content="Type:"
                    />
                  </Div>
                  <Spacer space="x_5" orientation="horizontal" />
                  <Text
                    type="caption"
                    isItalic={true}
                    content={getCardType(card)}
                    color="primaryHeading"
                  />
                </Flex>
                <Flex flexWrap={'nowrap'}>
                  <Div
                    borderRadius="default"
                    inset={{ horizontal: 'x_75' }}
                    backgroundColor="background"
                  >
                    <Text
                      color="primaryHeading"
                      weight="semiBold"
                      type="caption"
                      content="Send Type:"
                      whiteSpace="nowrap"
                    />
                  </Div>
                  <Spacer space="x_5" orientation="horizontal" />
                  <Text
                    type="caption"
                    isItalic={true}
                    content={
                      order?.sendType === CardSendType.Heartfelt
                        ? 'Heartfelt Prompting Card'
                        : 'System Card'
                    }
                    color="primaryHeading"
                    whiteSpace="nowrap"
                  />
                </Flex>
              </Div>
            </Div>
            <Spacer space="x2_5" orientation="horizontal" />
            <Div css={styles.settings}>
              <Spacer space="x2_5" orientation="horizontal" />
              <Div>
                <Select
                  frontLabel="Paper:"
                  onChange={handleUpdatePaper}
                  type="text"
                  options={getPaperOptions(card)}
                  selectedOptionIndex={
                    selectedPaperTypeOpt !== -1
                      ? selectedPaperTypeOpt
                      : undefined
                  }
                />
              </Div>
              <Spacer space="x2_5" orientation="horizontal" />
              <CardItemOption
                onClick={onToggleSendDelay}
                id={`send_delay_${lineIndex}`}
                isDisabled={order?.isBulk}
                disabledMessage={
                  'Scheduling not available: Bulk orders are sent immediately.'
                }
              >
                <Text type="caption" content="Schedule:" />
                <Spacer space="x_5" orientation="horizontal" />
                <Text
                  type="caption"
                  content={getSendDelayText(sendDelay)}
                  weight="bold"
                  lineHeight={1.2}
                />
                <Spacer space="x_5" orientation="horizontal" />
                <Div minWidth="20px">
                  <Icon
                    name="calendar"
                    size="xSmall"
                    primaryColor="primaryBodyText"
                  />
                </Div>
              </CardItemOption>
            </Div>
            <Spacer space="x4" orientation="horizontal" />
            <Div css={styles.cost}>
              <Div css={styles.price}>
                <Price
                  price={cost.price}
                  discountPrice={cost.discountPrice}
                  discountMessage={cost.discountMessage}
                />
              </Div>
              {quantity > 1 && <QuantityCounter quantity={quantity} />}
              <Spacer space="x4" orientation="horizontal" />
              <CardItemOption
                borderRadius="small"
                onClick={() => setShouldShowReplaceCardDialog(true)}
                disabledMessage={'Replace Card'}
              >
                <Div
                  id="replace_card_icon"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <DSIcon name="replace" size={26} />
                </Div>
              </CardItemOption>
            </Div>
          </Div>
          {didFlattenFail && (
            <Div>
              <Div
                backgroundColor="#FFF5F4"
                display="inline-flex"
                justifyContent="center"
                alignItems="center"
                inset={{ horizontal: 'x2', vertical: 'x_5' }}
                outset={{ top: 'x1' }}
                borderRadius="large"
              >
                <ImageErrorDisclaimer />
              </Div>
            </Div>
          )}
        </>
      ) : (
        <MobileCardLineItem
          quantity={quantity}
          lineIndex={lineIndex}
          card={card}
          sendDelay={sendDelay}
          cost={cost}
          onEditCard={onEditCard}
          onUpdatePaperType={onUpdatePaperType}
          onToggleSendDelay={onToggleSendDelay}
          order={order}
          onReplaceCard={() => setShouldShowReplaceCardDialog(true)}
          didFlattenFail={didFlattenFail}
        />
      )}
      {shouldShowReplaceCardDialog && (
        <ReplaceCardDialog
          isOpen={shouldShowReplaceCardDialog}
          onAccept={handleReplaceCard}
          onDecline={() =>
            setShouldShowReplaceCardDialog(!shouldShowReplaceCardDialog)
          }
          setIsOpen={setShouldShowReplaceCardDialog}
        />
      )}
    </>
  )
}

export default CardLineItem
